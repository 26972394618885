import * as constants from './constants'
import { fromJS } from 'immutable'
const defaultState = fromJS({
    collapsed: false,
})

export default (state = defaultState, action) => {
    switch (action.type) {
        case constants.NAVIGATIONBAR:
            return state.merge({
                names: action.names,
                eventKey: action.eventKey
            })
        case constants.TOGGLE:
            return state.set('collapsed', !action.collapsed);
        case constants.ONSELECT:
            return state.merge({
                names: action.names,
                eventKey: action.eventKey
            })
        case constants.SET_COUNTY:
            return state.set('county', action.county)
        default:
            return state
    }

}