import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Button, Input, Table } from 'antd'
import { actionCreators } from './store'
import '../../assets/css/content.min.css'
import axios from 'axios';
import Http from '../../http'
const { Search } = Input;
class Statistics1 extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    componentDidMount() {
        if (this.props.match.url == '/Statistics1/1') {
            this.props.loadlist('3_2')
            axios.get(Http.apiHost + '/API/Count/getAllMonthlyvisits?key=1').then((res) => {
                this.setState({
                    views: res.data.data
                })
            })
        }
        else if (this.props.match.url == '/Statistics1/2') {
            this.props.loadlist('3_3')
            axios.get(Http.apiHost + '/API/Count/getAllMonthlyvisits?key=3').then((res) => {
                this.setState({
                    views: res.data.data
                })
            })
        }
    }
    render() {
        const columns = [
            {
                title: '街道名',
                dataIndex: 'name',
                width: '25%'
            },
            {
                title: '路牌数',
                dataIndex: 'count2',
                width: '25%'
            },
            {
                title: '当月访问量',
                dataIndex: 'monthlyvisits',
                width: '25%'
            },
            {
                title: '总访问量',
                dataIndex: 'yearlyvisits',
                width: '25%'
            },
            // {
            //     title: '操作',
            //     render: () => {
            //         return (
            //             <Button className='minbutton' type="link" >查看详情</Button>
            //         )
            //     }
            // },

        ]
        const columns1 = [
            {
                title: '街道名',
                dataIndex: 'name',
                width: '25%'
            },
            {
                title: '路牌数',
                dataIndex: 'count2',
                width: '25%'
            },
            {
                title: '当月访问量',
                dataIndex: 'monthlyvisits',
                width: '25%'
            },
            {
                title: '总访问量',
                dataIndex: 'yearlyvisits',
                width: '25%'
            },
            // {
            //     title: '操作',
            //     render: () => {
            //         return (
            //             <Button className='minbutton' type="link" >查看详情</Button>
            //         )
            //     }
            // },
        ]
        const { names, eventKey, list, total, current, paginationchange, value, inputsearch, searchchange } = this.props
        const { views } = this.state
        const pagination = {
            current: current,
            total: total,
            defaultPageSize: 10,
            onChange: (current) => paginationchange(current, value, eventKey)
        }
        return (
            <div>
                <div className='gltitle'>
                    <div className='titleblue' />
                    <div className='titlename'>
                        {names}
                    </div>
                </div>
                <div>
                    <Search
                        className='search'
                        placeholder="请输入要查询名称"
                        style={{ width: 240 }}
                        allowClear
                        onSearch={(value) => inputsearch(value, eventKey)}
                        onChange={(e) => searchchange(e, eventKey)}
                    />
                    <span style={{ marginLeft: '20px' }}>总访问量：{views}</span>
                </div>
                {
                    eventKey == '3_2' ?
                        <Table rowKey='id' className='gltable' columns={columns} dataSource={list} pagination={pagination} />
                        :
                        <Table rowKey='id' className='gltable' columns={columns1} dataSource={list} pagination={pagination} />
                }
            </div>
        );
    }
}
const mapState = (state) => ({
    names: state.getIn(['navigationBar', 'names']),
    eventKey: state.getIn(['navigationBar', 'eventKey']),
    list: state.getIn(['statistics1', 'list']),
    total: state.getIn(['statistics1', 'total']),
    current: state.getIn(['statistics1', 'current']),
    value: state.getIn(['statistics1', 'value']),
})
const mapDispatch = (dispatch) => ({
    //获取内容
    loadlist(key) {
        dispatch(actionCreators.loadlist(key))
    },
    //点击分页获取内容
    paginationchange(current, value, key) {
        dispatch(actionCreators.paginationchange(current, value, key))
    },
    //搜索回车或点击搜索按钮时获取内容
    inputsearch(value, key) {
        dispatch(actionCreators.inputsearch(value, key))
    },
    //当搜索框内容为空时重新获取内容
    searchchange(e, key) {
        dispatch(actionCreators.searchchange(e.target.value, key))
    },
})
export default connect(mapState, mapDispatch)(Statistics1);