import * as constants from "./constants";
import axios from "axios";
import Http from "../../../http";
//获取列表内容
const loadlists = result => ({
  type: constants.LOADLISTS,
  list: result.list
});
//获取列表内容总数
const loadtotals = result => ({
  type: constants.LOADTOTALS,
  total: result.count
});
//设置current
const currents = current => ({
  type: constants.CURRENTS,
  current: current
});
//重置current
const initcurrents = () => ({
  type: constants.INITCURRENTS,
  current: 1
});
//获取搜索框内容
const values = value => ({
  type: constants.VALUES,
  values: value
});
const removelist = () => ({
  type: constants.REMOVEPUPOPOK,
  show: false
});
//获取表格内容
export const loadlist = key => {
  return dispatch => {
    const keys = {
      "1_1": "PlateNumber",
      "1_2": "RoadNumber",
      "1_3": "VillageNumber",
      "1_4": "Scenicspot",
      "1_5": "Road"
    };
    axios
      .get(Http.apiHost + "/API/" + keys[key] + "/getList1?page=1&size=10")
      .then(res => {
        const result = res.data.data;
        dispatch(loadlists(result));
        dispatch(loadtotals(result));
      });
  };
};
//语音弹窗显示
export const soureshow = () => ({
  type: constants.SOURCESHOW,
  sourcevisible: true
});
//语音弹窗隐藏
export const sourcehide = () => ({
  type: constants.SOURCEHIDE,
  sourcevisible: false
});
//图片展示弹窗显示
export const carouselshow = () => ({
  type: constants.CAROUSEL_SHOW,
  carouselvisible: true
});
//图片展示弹窗隐藏
export const carouselhide = () => ({
  type: constants.CAROUSEL_HIDE,
  carouselvisible: false
});
//二维码展示弹窗显示
export const onvisible = () => ({
  type: constants.ONVISIBLE,
  visible: true
});
//二维码展示弹窗隐藏
export const qrCodevisible = () => ({
  type: constants.QRCODEVISIBLE,
  visible: false
});
export const qrCodecontent = record => ({
  type: constants.QRCODECONTENT,
  content: record
});
export const removepupopok = (content, eventKey) => {
  return dispatch => {
    if (eventKey == "1_1") {
      axios
        .post(Http.apiHost + "/API/PlateNumber/del?id=" + content)
        .then(() => {
          axios
            .get(Http.apiHost + "/API/PlateNumber/getList1?page=1&size=10")
            .then(res => {
              const result = res.data.data;
              dispatch(loadlists(result));
              dispatch(loadtotals(result));
              dispatch(removelist());
            });
        });
    } else if (eventKey == "1_2") {
      axios
        .post(Http.apiHost + "/API/RoadNumber/del?id=" + content)
        .then(res => {
          axios
            .get(Http.apiHost + "/API/RoadNumber/getList1?page=1&size=10")
            .then(res => {
              const result = res.data.data;
              dispatch(loadlists(result));
              dispatch(loadtotals(result));
              dispatch(removelist());
            });
        });
    } else if (eventKey == "1_3") {
      axios
        .post(Http.apiHost + "/API/VillageNumber/del?id=" + content)
        .then(res => {
          axios
            .get(Http.apiHost + "/API/VillageNumber/getList1?page=1&size=10")
            .then(res => {
              const result = res.data.data;
              dispatch(loadlists(result));
              dispatch(loadtotals(result));
              dispatch(removelist());
            });
        });
    } else if (eventKey == "1_4") {
      axios
        .post(Http.apiHost + "/API/Scenicspot/del?id=" + content)
        .then(res => {
          axios
            .get(Http.apiHost + "/API/Scenicspot/getList1?page=1&size=10")
            .then(res => {
              const result = res.data.data;
              dispatch(loadlists(result));
              dispatch(loadtotals(result));
              dispatch(removelist());
            });
        });
    } else if (eventKey == "1_5") {
      axios.post(Http.apiHost + "/API/Road/del?id=" + content).then(res => {
        axios
          .get(Http.apiHost + "/API/Road/getList1?page=1&size=10")
          .then(res => {
            const result = res.data.data;
            dispatch(loadlists(result));
            dispatch(loadtotals(result));
            dispatch(removelist());
          });
      });
    }
  };
};
export const uploadingshow = () => ({
  type: constants.UPLOADING_SHOW,
  uploadvisible: true
});
export const uploadinghide = () => ({
  type: constants.UPLOADING_HIDE,
  uploadvisible: false
});
export const paginationchange = (current, key, values) => {
  return dispatch => {
    if (values == undefined || values == "") {
      if (key == "1_1") {
        axios
          .get(
            Http.apiHost +
              "/API/PlateNumber/getList1?page=" +
              current +
              "&size=10"
          )
          .then(res => {
            const result = res.data.data;
            dispatch(loadlists(result));
            dispatch(loadtotals(result));
            dispatch(currents(current));
          });
      } else if (key == "1_2") {
        axios
          .get(
            Http.apiHost +
              "/API/RoadNumber/getList1?page=" +
              current +
              "&size=10"
          )
          .then(res => {
            const result = res.data.data;
            dispatch(loadlists(result));
            dispatch(loadtotals(result));
            dispatch(currents(current));
          });
      } else if (key == "1_3") {
        axios
          .get(
            Http.apiHost +
              "/API/VillageNumber/getList1?page=" +
              current +
              "&size=10"
          )
          .then(res => {
            const result = res.data.data;
            dispatch(loadlists(result));
            dispatch(loadtotals(result));
            dispatch(currents(current));
          });
      } else if (key == "1_4") {
        axios
          .get(
            Http.apiHost +
              "/API/Scenicspot/getList1?page=" +
              current +
              "&size=10"
          )
          .then(res => {
            const result = res.data.data;
            dispatch(loadlists(result));
            dispatch(loadtotals(result));
            dispatch(currents(current));
          });
      } else if (key == "1_5") {
        axios
          .get(Http.apiHost + "/API/Road/getList1?page=" + current + "&size=10")
          .then(res => {
            const result = res.data.data;
            dispatch(loadlists(result));
            dispatch(loadtotals(result));
            dispatch(currents(current));
          });
      }
    } else {
      if (key == "1_1") {
        axios
          .get(
            Http.apiHost +
              "/API/PlateNumber/getList1?page=" +
              current +
              "&size=10&key=" +
              values
          )
          .then(res => {
            const result = res.data.data;
            dispatch(loadlists(result));
            dispatch(loadtotals(result));
            dispatch(currents(current));
          });
      } else if (key == "1_2") {
        axios
          .get(
            Http.apiHost +
              "/API/RoadNumber/getList1?page=" +
              current +
              "&size=10&key=" +
              values
          )
          .then(res => {
            const result = res.data.data;
            dispatch(loadlists(result));
            dispatch(loadtotals(result));
            dispatch(currents(current));
          });
      } else if (key == "1_3") {
        axios
          .get(
            Http.apiHost +
              "/API/VillageNumber/getList1?page=" +
              current +
              "&size=10&key=" +
              values
          )
          .then(res => {
            const result = res.data.data;
            dispatch(loadlists(result));
            dispatch(loadtotals(result));
            dispatch(currents(current));
          });
      } else if (key == "1_4") {
        axios
          .get(
            Http.apiHost +
              "/API/Scenicspot/getList1?page=" +
              current +
              "&size=10&key=" +
              values
          )
          .then(res => {
            const result = res.data.data;
            dispatch(loadlists(result));
            dispatch(loadtotals(result));
            dispatch(currents(current));
          });
      } else if (key == "1_5") {
        axios
          .get(
            Http.apiHost +
              "/API/Road/getList1?page=" +
              current +
              "&size=10&key=" +
              values
          )
          .then(res => {
            const result = res.data.data;
            dispatch(loadlists(result));
            dispatch(loadtotals(result));
            dispatch(currents(current));
          });
      }
    }
  };
};
export const inputsearch = (value, key) => {
  return dispatch => {
    if (key == "1_1") {
      axios
        .get(
          Http.apiHost + "/API/PlateNumber/getList1?page=1&size=10&key=" + value
        )
        .then(res => {
          const result = res.data.data;
          dispatch(loadlists(result));
          dispatch(loadtotals(result));
          dispatch(values(value));
          dispatch(initcurrents());
        });
    } else if (key == "1_2") {
      axios
        .get(
          Http.apiHost + "/API/RoadNumber/getList1?page=1&size=10&key=" + value
        )
        .then(res => {
          const result = res.data.data;
          dispatch(loadlists(result));
          dispatch(loadtotals(result));
          dispatch(values(value));
          dispatch(initcurrents());
        });
    } else if (key == "1_3") {
      axios
        .get(
          Http.apiHost +
            "/API/VillageNumber/getList1?page=1&size=10&key=" +
            value
        )
        .then(res => {
          const result = res.data.data;
          dispatch(loadlists(result));
          dispatch(loadtotals(result));
          dispatch(values(value));
          dispatch(initcurrents());
        });
    } else if (key == "1_4") {
      axios
        .get(
          Http.apiHost + "/API/Scenicspot/getList1?page=1&size=10&key=" + value
        )
        .then(res => {
          const result = res.data.data;
          dispatch(loadlists(result));
          dispatch(loadtotals(result));
          dispatch(values(value));
          dispatch(initcurrents());
        });
    } else if (key == "1_5") {
      axios
        .get(Http.apiHost + "/API/Road/getList1?page=1&size=10&key=" + value)
        .then(res => {
          const result = res.data.data;
          dispatch(loadlists(result));
          dispatch(loadtotals(result));
          dispatch(values(value));
          dispatch(initcurrents());
        });
    }
  };
};
export const searchchange = (value, key) => {
  return dispatch => {
    if (value == "") {
      if (key == "1_1") {
        axios
          .get(Http.apiHost + "/API/PlateNumber/getList1?page=1&size=10")
          .then(res => {
            const result = res.data.data;
            dispatch(loadlists(result));
            dispatch(loadtotals(result));
            dispatch(initcurrents());
            dispatch(values());
          });
      } else if (key == "1_2") {
        axios
          .get(Http.apiHost + "/API/RoadNumber/getList1?page=1&size=10")
          .then(res => {
            const result = res.data.data;
            dispatch(loadlists(result));
            dispatch(loadtotals(result));
            dispatch(initcurrents());
            dispatch(values());
          });
      } else if (key == "1_3") {
        axios
          .get(Http.apiHost + "/API/VillageNumber/getList1?page=1&size=10")
          .then(res => {
            const result = res.data.data;
            dispatch(loadlists(result));
            dispatch(loadtotals(result));
            dispatch(initcurrents());
            dispatch(values());
          });
      } else if (key == "1_4") {
        axios
          .get(Http.apiHost + "/API/Scenicspot/getList1?page=1&size=10")
          .then(res => {
            const result = res.data.data;
            dispatch(loadlists(result));
            dispatch(loadtotals(result));
            dispatch(initcurrents());
            dispatch(values());
          });
      } else if (key == "1_5") {
        axios
          .get(Http.apiHost + "/API/Road/getList1?page=1&size=10")
          .then(res => {
            const result = res.data.data;
            dispatch(loadlists(result));
            dispatch(loadtotals(result));
            dispatch(initcurrents());
            dispatch(values());
          });
      }
    }
  };
};
export const addandhide = key => {
  return dispatch => {
    if (key == "1_1") {
      axios
        .get(Http.apiHost + "/API/PlateNumber/getList1?page=1&size=10")
        .then(res => {
          const result = res.data.data;
          dispatch(loadlists(result));
          dispatch(loadtotals(result));
          dispatch(uploadinghide());
          dispatch(initcurrents());
        });
    } else if (key == "1_2") {
      axios
        .get(Http.apiHost + "/API/RoadNumber/getList1?page=1&size=10")
        .then(res => {
          const result = res.data.data;
          dispatch(loadlists(result));
          dispatch(loadtotals(result));
          dispatch(uploadinghide());
          dispatch(initcurrents());
        });
    } else if (key == "1_3") {
      axios
        .get(Http.apiHost + "/API/VillageNumber/getList1?page=1&size=10")
        .then(res => {
          const result = res.data.data;
          dispatch(loadlists(result));
          dispatch(loadtotals(result));
          dispatch(uploadinghide());
          dispatch(initcurrents());
        });
    } else if (key == "1_4") {
      axios
        .get(Http.apiHost + "/API/Scenicspot/getList1?page=1&size=10")
        .then(res => {
          const result = res.data.data;
          dispatch(loadlists(result));
          dispatch(loadtotals(result));
          dispatch(uploadinghide());
          dispatch(initcurrents());
        });
    } else if (key == "1_5") {
      axios
        .get(Http.apiHost + "/API/Road/getList1?page=1&size=10")
        .then(res => {
          const result = res.data.data;
          dispatch(loadlists(result));
          dispatch(loadtotals(result));
          dispatch(uploadinghide());
          dispatch(initcurrents());
        });
    }
  };
};
export const amendshow = () => ({
  type: constants.AMEND_SHOW,
  amendvisible: true
});
export const amendhide = () => ({
  type: constants.AMEND_HIDE,
  amendvisible: false
});
export const amendandhide = (key, current) => {
  return dispatch => {
    if (key == "1_1") {
      axios
        .get(
          Http.apiHost +
            "/API/PlateNumber/getList1?page=" +
            current +
            "&size=10"
        )
        .then(res => {
          const result = res.data.data;
          dispatch(loadlists(result));
          dispatch(loadtotals(result));
          dispatch(amendhide());
          dispatch(currents(current));
        });
    } else if (key == "1_2") {
      axios
        .get(
          Http.apiHost + "/API/RoadNumber/getList1?page=" + current + "&size=10"
        )
        .then(res => {
          const result = res.data.data;
          dispatch(loadlists(result));
          dispatch(loadtotals(result));
          dispatch(amendhide());
          dispatch(currents(current));
        });
    } else if (key == "1_3") {
      axios
        .get(
          Http.apiHost +
            "/API/VillageNumber/getList1?page=" +
            current +
            "&size=10"
        )
        .then(res => {
          const result = res.data.data;
          dispatch(loadlists(result));
          dispatch(loadtotals(result));
          dispatch(amendhide());
          dispatch(currents(current));
        });
    } else if (key == "1_4") {
      axios
        .get(
          Http.apiHost + "/API/Scenicspot/getList1?page=" + current + "&size=10"
        )
        .then(res => {
          const result = res.data.data;
          dispatch(loadlists(result));
          dispatch(loadtotals(result));
          dispatch(amendhide());
          dispatch(currents(current));
        });
    } else if (key == "1_5") {
      axios
        .get(Http.apiHost + "/API/Road/getList1?page=" + current + "&size=10")
        .then(res => {
          const result = res.data.data;
          dispatch(loadlists(result));
          dispatch(loadtotals(result));
          dispatch(amendhide());
          dispatch(currents(current));
        });
    }
  };
};
export const maintainshow = () => ({
  type: constants.MAINTAIN_SHOW,
  maintainvisible: true
});
export const maintainhide = () => ({
  type: constants.MAINTAIN_HIDE,
  maintainvisible: false
});
export const maintainsuccess = (key, current) => {
  return dispatch => {
    if (key == "1_1") {
      axios
        .get(
          Http.apiHost +
            "/API/PlateNumber/getList1?page=" +
            current +
            "&size=10"
        )
        .then(res => {
          const result = res.data.data;
          dispatch(loadlists(result));
          dispatch(loadtotals(result));
          dispatch(maintainhide());
          dispatch(currents(current));
        });
    } else if (key == "1_2") {
      axios
        .get(
          Http.apiHost + "/API/RoadNumber/getList1?page=" + current + "&size=10"
        )
        .then(res => {
          const result = res.data.data;
          dispatch(loadlists(result));
          dispatch(loadtotals(result));
          dispatch(maintainhide());
          dispatch(currents(current));
        });
    } else if (key == "1_3") {
      axios
        .get(
          Http.apiHost +
            "/API/VillageNumber/getList1?page=" +
            current +
            "&size=10"
        )
        .then(res => {
          const result = res.data.data;
          dispatch(loadlists(result));
          dispatch(loadtotals(result));
          dispatch(maintainhide());
          dispatch(currents(current));
        });
    } else if (key == "1_4") {
      axios
        .get(
          Http.apiHost + "/API/Scenicspot/getList1?page=" + current + "&size=10"
        )
        .then(res => {
          const result = res.data.data;
          dispatch(loadlists(result));
          dispatch(loadtotals(result));
          dispatch(maintainhide());
          dispatch(currents(current));
        });
    } else if (key == "1_5") {
      axios
        .get(Http.apiHost + "/API/Road/getList1?page=" + current + "&size=10")
        .then(res => {
          const result = res.data.data;
          dispatch(loadlists(result));
          dispatch(loadtotals(result));
          dispatch(maintainhide());
          dispatch(currents(current));
        });
    }
  };
};
