import * as constants from './constants'
import axios from 'axios';
import Http from '../../../http'
export const getfeedbacklistdata = (result) => ({
    type: constants.GET_FEEDBACKLIST_DATA,
    list: result
})
export const getfeedbacklisttotal = (result) => ({
    type: constants.GET_FEEDBACKLIST_TOTAL,
    total: result
})
export const setinputvalue = (value) => ({
    type: constants.SET_INPUT_VALUE,
    value: value
})
export const setcurrent = (current) => ({
    type: constants.SET_CURRENT,
    current: current
})
export const getfeedbacklist = () => {
    return (dispatch) => {
        axios.get(Http.apiHost + '/API/Feedback/getList?page=1&size=10').then((res) => {
            const result = res.data.data
            dispatch(getfeedbacklistdata(result.list))
            dispatch(getfeedbacklisttotal(result.count))
        })
    }
}
export const paginationchange = (current, value) => {
    return (dispatch) => {
        if (value == undefined || value == '') {
            axios.get(Http.apiHost + '/API/Feedback/getList?page=' + current + '&size=10').then((res) => {
                const result = res.data.data
                dispatch(getfeedbacklistdata(result.list))
                dispatch(getfeedbacklisttotal(result.count))
                dispatch(setcurrent(current))
            })
        }
        else {
            axios.get(Http.apiHost + '/API/Feedback/getList?page=' + current + '&size=10&key=' + value).then((res) => {
                const result = res.data.data
                dispatch(getfeedbacklistdata(result.list))
                dispatch(getfeedbacklisttotal(result.count))
                dispatch(setcurrent(current))
            })
        }
    }
}
export const inputsearch = (value) => {
    return (dispatch) => {
        axios.get(Http.apiHost + '/API/Feedback/getList?page=1&size=10&key=' + value).then((res) => {
            const result = res.data.data
            dispatch(getfeedbacklistdata(result.list))
            dispatch(getfeedbacklisttotal(result.count))
            dispatch(setinputvalue(value))
            dispatch(setcurrent(1))
        })
    }
}
export const searchchange = (e) => {
    return (dispatch) => {
        if (e == '') {
            axios.get(Http.apiHost + '/API/Feedback/getList?page=1&size=10').then((res) => {
                const result = res.data.data
                dispatch(getfeedbacklistdata(result.list))
                dispatch(getfeedbacklisttotal(result.count))
                dispatch(setinputvalue())
                dispatch(setcurrent(1))
            })
        }
    }
}