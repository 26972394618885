import React, { Component } from "react";
import { Layout, Menu, Icon } from "antd";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { actionCreators } from "./store";
import "./css/navigationBar.min.css";
import Logo from "../../assets/imgs/icon/logo1.png";
const { Header, Sider, Content, Footer } = Layout;
const { SubMenu } = Menu;
class NavigationBar extends Component {
  constructor(props) {
    super(props);
  }
  //根据url往sessionStorage写入内容
  componentWillMount() {
    const url = this.props.location.pathname;
    const county = sessionStorage.getItem("county");
    this.props.setcounty(county);
    if (url == "/" || url == "/BasicManagement/1") {
      this.props.navigationBar("1_1", "门牌管理");
      sessionStorage.setItem("key", "1_1");
      sessionStorage.setItem("openkey", "1");
    } else if (url == "/BasicManagement/2") {
      this.props.navigationBar("1_2", "路牌管理");
      sessionStorage.setItem("key", "1_2");
      sessionStorage.setItem("openkey", "1");
    } else if (url == "/BasicManagement/3") {
      this.props.navigationBar("1_3", "村牌管理");
      sessionStorage.setItem("key", "1_3");
      sessionStorage.setItem("openkey", "1");
    } else if (url == "/BasicManagement/4") {
      this.props.navigationBar("1_4", "景区管理");
      sessionStorage.setItem("key", "1_4");
      sessionStorage.setItem("openkey", "1");
    } else if (url == "/BasicManagement/5") {
      this.props.navigationBar("1_5", "道路管理");
      sessionStorage.setItem("key", "1_5");
      sessionStorage.setItem("openkey", "1");
    } else if (url == "/Maintain") {
      this.props.navigationBar("2_1", "维护记录");
      sessionStorage.setItem("key", "2_1");
      sessionStorage.setItem("openkey", "2");
    } else if (url == "/MassivePoint") {
      this.props.navigationBar("3_1", "海量点");
      sessionStorage.setItem("key", "3_1");
      sessionStorage.setItem("openkey", "3");
    } else if (url == "/Statistics1/1") {
      this.props.navigationBar("3_2", "门牌统计");
      sessionStorage.setItem("key", "3_2");
      sessionStorage.setItem("openkey", "3");
    } else if (url == "/Statistics1/2") {
      this.props.navigationBar("3_3", "路牌统计");
      sessionStorage.setItem("key", "3_3");
      sessionStorage.setItem("openkey", "3");
    } else if (url == "/Statistics2/1") {
      this.props.navigationBar("3_4", "村牌统计");
      sessionStorage.setItem("key", "3_4");
      sessionStorage.setItem("openkey", "3");
    } else if (url == "/Statistics2/2") {
      this.props.navigationBar("3_5", "景区统计");
      sessionStorage.setItem("key", "3_5");
      sessionStorage.setItem("openkey", "3");
    } else if (url == "/FeedbackList") {
      this.props.navigationBar("4_2", "反馈列表");
      sessionStorage.setItem("key", "4_2");
      sessionStorage.setItem("openkey", "4");
    } else if (url == "/Userinfo") {
      this.props.navigationBar("5_1", "用户信息");
      sessionStorage.setItem("key", "5_1");
      sessionStorage.setItem("openkey", "5");
    } else if (url == "/ContentManagement/1") {
      this.props.navigationBar("6_1", "华侨地名");
      sessionStorage.setItem("key", "6_1");
      sessionStorage.setItem("openkey", "6");
    } else if (url == "/ContentManagement/2") {
      this.props.navigationBar("6_2", "古村流韵");
      sessionStorage.setItem("key", "6_2");
      sessionStorage.setItem("openkey", "6");
    } else if (url == "/ContentManagement/3") {
      this.props.navigationBar("6_3", "特色美食");
      sessionStorage.setItem("key", "6_3");
      sessionStorage.setItem("openkey", "6");
    } else if (url == "/ContentManagement/4") {
      this.props.navigationBar("6_4", "民俗风情");
      sessionStorage.setItem("key", "6_4");
      sessionStorage.setItem("openkey", "6");
    } else if (url == "/ContentManagement/5") {
      this.props.navigationBar("6_5", "风景名胜");
      sessionStorage.setItem("key", "6_5");
      sessionStorage.setItem("openkey", "6");
    } else if (url == "/ContentManagement/6") {
      this.props.navigationBar("6_6", "非遗文化");
      sessionStorage.setItem("key", "6_6");
      sessionStorage.setItem("openkey", "6");
    } else if (url == "/ContentManagement/7") {
      this.props.navigationBar("6_7", "地名文化");
      sessionStorage.setItem("key", "6_7");
      sessionStorage.setItem("openkey", "6");
    } else if (url == "/ContentManagement/8") {
      this.props.navigationBar("6_8", "街巷地名");
      sessionStorage.setItem("key", "6_8");
      sessionStorage.setItem("openkey", "6");
    } else if (url == "/ContentManagement/9") {
      this.props.navigationBar("6_9", "行政区划");
      sessionStorage.setItem("key", "6_9");
      sessionStorage.setItem("openkey", "6");
    }
  }
  //退出
  eliminate = () => {
    sessionStorage.clear();
    window.location.href = "/";
  };
  render() {
    const { collapsed, toggle, onselect, county } = this.props;
    const key = [sessionStorage.getItem("key")];
    const openkey = [sessionStorage.getItem("openkey")];
    const username = sessionStorage.getItem("name");
    const qtmenu = [
      {
        index: "1",
        key: "6_1",
        name: "华侨地名"
      },
      {
        index: "2",
        key: "6_2",
        name: "古村流韵"
      },
      {
        index: "3",
        key: "6_3",
        name: "特色美食"
      },
      {
        index: "4",
        key: "6_4",
        name: "民俗风情"
      },
      {
        index: "5",
        key: "6_5",
        name: "风景名胜"
      },
      {
        index: "6",
        key: "6_6",
        name: "非遗文化"
      },
      {
        index: "7",
        key: "6_7",
        name: "地名文化"
      },
      {
        index: "8",
        key: "6_8",
        name: "街巷地名"
      },
      {
        index: "9",
        key: "6_9",
        name: "行政区划"
      }
    ];
    return (
      <Layout>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          width={256}
          style={{ minHeight: "100vh" }}
        >
          <div className="logo">
            <img src={Logo} alt="" />
            <span>青田智慧地名应用平台</span>
          </div>
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={key}
            defaultOpenKeys={openkey}
            onSelect={item => onselect(item)}
          >
            <SubMenu
              key="1"
              title={
                <span>
                  <Icon type="read" />
                  <span>地名管理</span>
                </span>
              }
            >
              <Menu.Item key="1_1">
                <Link to="/BasicManagement/1">门牌管理</Link>
              </Menu.Item>
              <Menu.Item key="1_2">
                <Link to="/BasicManagement/2">路牌管理</Link>
              </Menu.Item>
              <Menu.Item key="1_3">
                <Link to="/BasicManagement/3">村牌管理</Link>
              </Menu.Item>
              <Menu.Item key="1_4">
                <Link to="/BasicManagement/4">景区管理</Link>
              </Menu.Item>
              <Menu.Item key="1_5">
                <Link to="/BasicManagement/5">道路管理</Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu
              key="2"
              title={
                <span>
                  <Icon type="tool" />
                  <span>维护管理</span>
                </span>
              }
            >
              <Menu.Item key="2_1">
                <Link to="/Maintain">维护记录</Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu
              key="3"
              title={
                <span>
                  <Icon type="line-chart" />
                  <span>数据统计</span>
                </span>
              }
            >
              <Menu.Item key="3_1">
                <Link to="/MassivePoint">海量点</Link>
              </Menu.Item>
              <Menu.Item key="3_2">
                <Link to="/Statistics1/1">门牌统计</Link>
              </Menu.Item>
              <Menu.Item key="3_3">
                <Link to="/Statistics1/2">路牌统计</Link>
              </Menu.Item>
              <Menu.Item key="3_4">
                <Link to="/Statistics2/1">村牌统计</Link>
              </Menu.Item>
              <Menu.Item key="3_5">
                <Link to="/Statistics2/2">景区统计</Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu
              key="4"
              title={
                <span>
                  <Icon type="form" />
                  <span>反馈管理</span>
                </span>
              }
            >
              {/* <Menu.Item key="4_1">
                                <Link to='/FeedbackSet'>
                                    反馈设置
                                </Link>
                            </Menu.Item> */}
              <Menu.Item key="4_2">
                <Link to="/FeedbackList">反馈列表</Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu
              key="6"
              title={
                <span>
                  <Icon type="read" />
                  <span>信息管理</span>
                </span>
              }
            >
              {qtmenu.map(item => {
                return (
                  <Menu.Item key={item.key}>
                    <Link to={"/ContentManagement/" + item.index}>
                      {item.name}
                    </Link>
                  </Menu.Item>
                );
              })}
            </SubMenu>
			<SubMenu
				key="7"
				title={
				  <span>
				    <Icon type="form" />
				    <span>账户管理</span>
				  </span>
				}
			>
			<Menu.Item>
				<Link to={"/ChangePassword"} >修改密码</Link>
			</Menu.Item>
			</SubMenu>
          </Menu>
        </Sider>
        <Layout>
          <Header className="header">
            <Icon
              className="trigger"
              type={collapsed ? "menu-unfold" : "menu-fold"}
              onClick={() => toggle(collapsed)}
            />
            <div className="headright">
              <div>
                <Icon type="user" />
                <span>{username}</span>
              </div>
              <div className="eliminate" onClick={this.eliminate}>
                <Icon type="poweroff" />
                <span>退出</span>
              </div>
            </div>
          </Header>
          <Content
            style={{
              margin: "24px",
              padding: "16px",
              background: "#fff",
              minHeight: 280
            }}
          >
            {this.props.children}
          </Content>
          <Footer className="footer">
            <span>民政局</span>
          </Footer>
        </Layout>
      </Layout>
    );
  }
}
const mapState = state => ({
  collapsed: state.getIn(["navigationBar", "collapsed"]),
  eventKey: state.getIn(["navigationBar", "eventKey"]),
  county: state.getIn(["navigationBar", "county"])
});

const mapDispatch = dispatch => ({
  navigationBar(key, names) {
    dispatch(actionCreators.navigationBar(key, names));
  },
  //收起或展开侧边栏
  toggle(collapsed) {
    dispatch(actionCreators.toggle(collapsed));
  },
  //点击侧边栏内的导航
  onselect(item) {
    dispatch(actionCreators.onselect(item.item.props));
  },
  setcounty(name) {
    dispatch(actionCreators.setcounty(name));
  }
});
export default connect(mapState, mapDispatch)(NavigationBar);
