import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Input, Table, Popconfirm, Tabs, Switch } from "antd";
import { actionCreators } from "./store";
import Uploading from "./compoents/uploading";
import Compile from "./compoents/compile";
import "../../assets/css/content.min.css";
const { Search } = Input;
const { TabPane } = Tabs;
class ContentManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    if (this.props.match.url == "/ContentManagement/1") {
      this.props.contentManagementload("6_1");
    } else if (this.props.match.url == "/ContentManagement/2") {
      this.props.contentManagementload("6_2");
    } else if (this.props.match.url == "/ContentManagement/3") {
      this.props.contentManagementload("6_3");
    } else if (this.props.match.url == "/ContentManagement/4") {
      this.props.contentManagementload("6_4");
    } else if (this.props.match.url == "/ContentManagement/5") {
      this.props.contentManagementload("6_5");
    } else if (this.props.match.url == "/ContentManagement/6") {
      this.props.contentManagementload("6_6");
    } else if (this.props.match.url == "/ContentManagement/7") {
      const tabkey = sessionStorage.getItem("tabskey");
      if (tabkey == null) {
        sessionStorage.setItem("tabskey", "tabkey1");
      } else {
        sessionStorage.setItem("tabskey", tabkey);
      }
      this.props.contentManagementload("6_7");
    } else if (this.props.match.url == "/ContentManagement/8") {
      this.props.contentManagementload("6_8");
    } else if (this.props.match.url == "/ContentManagement/9") {
      this.props.contentManagementload("6_9");
    }
  }
  render() {
    const {
      names,
      eventKey,
      county,
      current,
      size,
      uploadingshow,
      uploadvisible,
      list,
      count,
      paginationchange,
      inputsearch,
      searchchange,
      values,
      removepupopok,
      compile,
      compilevisible,
      tabsonchange
    } = this.props;
    const columns = [
      {
        title: "编号",
        render: (text, record, index) => {
          return <span>{(current - 1) * size + index + 1}</span>;
        }
      },
      {
        title: "标题",
        dataIndex: "name"
      },
      {
        title: "操作",
        render: (text, record) => {
          return (
            <span>
              <Button
                className="minbutton"
                type="link"
                onClick={() => compile(record)}
              >
                编辑
              </Button>
              <Popconfirm
                title="是否删除该数据?"
                okText="确认"
                cancelText="取消"
                onConfirm={() => removepupopok(record, eventKey, current)}
              >
                <Button className="minbutton" type="link">
                  删除
                </Button>
              </Popconfirm>
            </span>
          );
        }
      }
    ];
    const pagination = {
      current: current,
      total: count,
      defaultPageSize: 10,
      onChange: current => paginationchange(current, eventKey, values)
    };
    const operations = (
      <div>
        <Button type="primary" onClick={() => uploadingshow()}>
          上传信息
        </Button>
        <Search
          className="search"
          placeholder="请输入要查询标题"
          style={{ width: 240 }}
          allowClear
          onSearch={value => inputsearch(value, eventKey)}
          onChange={e => searchchange(e, eventKey)}
        />
      </div>
    );
    const tabskey = sessionStorage.getItem("tabskey");
    return (
      <div>
        <div className="gltitle">
          <div className="titleblue" />
          <div className="titlename">{names}</div>
        </div>
        {eventKey == "6_7" ? (
          <Tabs
            defaultActiveKey={tabskey}
            tabBarExtraContent={operations}
            onChange={e => tabsonchange(e, eventKey)}
          >
            <TabPane tab="地名故事" key="tabkey1">
              <Table
                rowKey="id"
                className="gltable"
                columns={columns}
                dataSource={list}
                pagination={pagination}
              />
            </TabPane>
            <TabPane tab="红色地名" key="tabkey2">
              <Table
                rowKey="id"
                className="gltable"
                columns={columns}
                dataSource={list}
                pagination={pagination}
              />
            </TabPane>
            <TabPane tab="地名诗词" key="tabkey3">
              <Table
                rowKey="id"
                className="gltable"
                columns={columns}
                dataSource={list}
                pagination={pagination}
              />
            </TabPane>
            <TabPane tab="文化保护" key="tabkey4">
              <Table
                rowKey="id"
                className="gltable"
                columns={columns}
                dataSource={list}
                pagination={pagination}
              />
            </TabPane>
          </Tabs>
        ) : (
          <>
            <div className="glbutton">
              <div>
                <Button type="primary" onClick={() => uploadingshow()}>
                  上传信息
                </Button>
                <Search
                  className="search"
                  placeholder="请输入要查询标题"
                  style={{ width: 240 }}
                  allowClear
                  onSearch={value => inputsearch(value, eventKey, county)}
                  onChange={e => searchchange(e, eventKey, county)}
                />
              </div>
            </div>
            <Table
              rowKey="id"
              className="gltable"
              columns={columns}
              dataSource={list}
              pagination={pagination}
            />
          </>
        )}
        {uploadvisible ? <Uploading /> : null}
        {compilevisible ? <Compile /> : null}
      </div>
    );
  }
}
const mapState = state => ({
  names: state.getIn(["navigationBar", "names"]),
  eventKey: state.getIn(["navigationBar", "eventKey"]),
  county: state.getIn(["navigationBar", "county"]),
  current: state.getIn(["contentManagement", "current"]),
  size: state.getIn(["contentManagement", "size"]),
  uploadvisible: state.getIn(["contentManagement", "uploadvisible"]),
  compilevisible: state.getIn(["contentManagement", "compilevisible"]),
  list: state.getIn(["contentManagement", "list"]),
  count: state.getIn(["contentManagement", "count"]),
  values: state.getIn(["contentManagement", "values"])
});
const mapDispatch = dispatch => ({
  contentManagementload(id) {
    dispatch(actionCreators.contentManagementload(id));
  },
  uploadingshow() {
    dispatch(actionCreators.uploadingshow());
  },
  inputsearch(value, key) {
    dispatch(actionCreators.inputsearch(value, key));
  },
  searchchange(e, key) {
    dispatch(actionCreators.searchchange(e.target.value, key));
  },
  paginationchange(index, key, values) {
    dispatch(actionCreators.paginationchange(index, key, values));
  },
  removepupopok(value, key, current) {
    dispatch(actionCreators.removepupopok(value, key, current));
  },
  compile(record) {
    dispatch(actionCreators.compile(record));
  },
  tabsonchange(e, eventKey) {
    dispatch(actionCreators.tabsonchange(e, eventKey));
  }
  // tablerow(record) {
  //     dispatch(actionCreators.tablerow(record))
  // }
});
export default connect(mapState, mapDispatch)(ContentManagement);
