import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Input, Table, Popconfirm, Icon, Radio } from "antd";
import { actionCreators } from "./store";
import axios from "axios";
import Http from "../../http";
import "../../assets/css/content.min.css";
import Popup from "./compoents/pupop";
import Soure from "./compoents/soure";
import Uploading from "./compoents/uploading";
import Carouselpupop from "./compoents/carouselpupop";
import Amend from "./compoents/amend";
import Maintain from "./compoents/maintain";
import Maps from "../Map";
import JSZip from "jszip";
import FileSaver from "file-saver";
const QRCode = require("qrcode-react");
const { Search } = Input;
class BasicManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listdisplay: true,
      data: []
    };
  }
  componentDidMount() {
    if (
      this.props.match.url == "/" ||
      this.props.match.url == "/BasicManagement/1"
    ) {
      this.props.loadlist("1_1");
    } else if (this.props.match.url == "/BasicManagement/2") {
      this.props.loadlist("1_2");
    } else if (this.props.match.url == "/BasicManagement/3") {
      this.props.loadlist("1_3");
    } else if (this.props.match.url == "/BasicManagement/4") {
      this.props.loadlist("1_4");
    } else if (this.props.match.url == "/BasicManagement/5") {
      this.props.loadlist("1_5");
    }
  }
  listandmap = e => {
    if (e.target.value == "list") {
      this.setState({
        listdisplay: true
      });
    } else if (e.target.value == "map") {
      this.setState({
        listdisplay: false
      });
    }
  };
  //批量下载二维码
  download = key => {
    const aaa = {
      "1_1": "PlateNumber",
      "1_2": "RoadNumber",
      "1_3": "VillageNumber",
      "1_4": "Scenicspot",
      "1_5": "Road"
    };
    axios
      .get(Http.apiHost + "/API/" + aaa[key] + "/getNameAndUrl")
      .then(res => {
        this.setState({
          data: res.data.data
        });
        setTimeout(() => {
          this.toZip(res.data.data, "二维码", key);
        }, 1000);
      });
  };
  //打包成zip文件
  toZip = (imgSrcList, fileName, key) => {
    let index = {
      "1_1": "门牌",
      "1_2": "路牌",
      "1_3": "村牌",
      "1_4": "景区",
      "1_5": "道路"
    };
    let zip = new JSZip(); //实例化一个压缩文件对象
    let imgFolder = zip.folder(index[key] + fileName); //新建一个图片文件夹用来存放图片，参数为文件名
    imgSrcList.map((item, index) => {
      let Qr = document.getElementById("qrid").children[index];
      let image = new Image();
      image.src = Qr.toDataURL("image/png");
      const newimage = image.src.slice(22);
      imgFolder.file(item.name + ".png", newimage, { base64: true });
    });
    setTimeout(() => {
      zip.generateAsync({ type: "blob" }).then(function(content) {
        FileSaver.saveAs(content, index[key] + fileName + ".zip");
      });
    }, 1000);
  };
  render() {
    const {
      names,
      onvisible,
      current,
      values,
      qrCodecontent,
      eventKey,
      list,
      total,
      removepupopok,
      soureshow,
      uploadingshow,
      carouselshow,
      paginationchange,
      inputsearch,
      searchchange,
      amendshow,
      amendvisible,
      uploadvisible,
      maintainshow,
      maintainvisible,
      sourcevisible,
      county,
      adjunctionvisible,
      setadjunctionvisible
    } = this.props;
    const columns = [
      {
        title: "名称",
        dataIndex: "name"
      },
      {
        title: "中文",
        dataIndex: "mandarinWord",
        width: "20%",
        render: text => {
          return (
            <span
              title={text}
              style={{
                display: "block",
                width: "260px",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden"
              }}
            >
              {text}
            </span>
          );
        }
      },
      {
        title: "英文",
        dataIndex: "englishWord",
        width: "20%",
        render: text => {
          return (
            <span
              title={text}
              style={{
                display: "block",
                width: "260px",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden"
              }}
            >
              {text}
            </span>
          );
        }
      },
      {
        title: "类型",
        dataIndex: "type"
      },
      {
        title: "二维码地址",
        dataIndex: "url",
        render: text => {
          return (
            <span>
              {text} &nbsp;{" "}
              <Button
                className="minbutton mini"
                type="link"
                onClick={() => onvisible()}
              >
                预览
              </Button>
            </span>
          );
        }
      },
      {
        title: "图片",
        // dataIndex: 'pictureUrl',
        render: (text, record) => {
          return (
            <Button
              className="minbutton mini"
              type="link"
              onClick={() => carouselshow()}
            >
              {record.pictureUrl.length}张
            </Button>
          );
        }
      },
      {
        title: "语音",
        // dataIndex: 'voiceUrl',
        render: text => {
          return (
            <Button
              className="minbutton blackbutton"
              type="link"
              onClick={() => soureshow()}
            >
              <Icon type="caret-right" />
            </Button>
          );
        }
      },
      {
        title: "操作",
        // dataIndex: 'operation',
        render: (text, record) => {
          return (
            <span>
              <Button
                className="minbutton"
                type="link"
                onClick={() => maintainshow()}
              >
                维护
              </Button>
              <Button
                className="minbutton"
                type="link"
                onClick={() => amendshow()}
              >
                修改
              </Button>
              <Popconfirm
                title="是否删除该数据?"
                okText="确认"
                cancelText="取消"
                onConfirm={() => removepupopok(record, eventKey)}
              >
                <Button className="minbutton" type="link">
                  删除
                </Button>
              </Popconfirm>
            </span>
          );
        }
      }
    ];
    const columns1 = [
      {
        title: "路牌编号",
        dataIndex: "roadNum"
      },
      {
        title: "名称",
        dataIndex: "name"
      },
      {
        title: "中文",
        dataIndex: "mandarinWord",
        width: "15%",
        render: text => {
          return (
            <span
              title={text}
              style={{
                display: "block",
                width: "200px",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden"
              }}
            >
              {text}
            </span>
          );
        }
      },
      {
        title: "英文",
        dataIndex: "englishWord",
        width: "15%",
        render: text => {
          return (
            <span
              title={text}
              style={{
                display: "block",
                width: "200px",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden"
              }}
            >
              {text}
            </span>
          );
        }
      },
      {
        title: "类型",
        dataIndex: "type"
      },
      {
        title: "二维码地址",
        dataIndex: "url",
        render: text => {
          return (
            <span>
              {text} &nbsp;{" "}
              <Button
                className="minbutton mini"
                type="link"
                onClick={() => onvisible()}
              >
                预览
              </Button>
            </span>
          );
        }
      },
      {
        title: "图片",
        // dataIndex: 'pictureUrl',
        render: (text, record) => {
          return (
            <Button
              className="minbutton mini"
              type="link"
              onClick={() => carouselshow()}
            >
              {record.pictureUrl.length}张
            </Button>
          );
        }
      },
      {
        title: "语音",
        // dataIndex: 'voice',
        render: () => {
          return (
            <Button
              className="minbutton blackbutton"
              type="link"
              onClick={() => soureshow()}
            >
              <Icon type="caret-right" />
            </Button>
          );
        }
      },
      {
        title: "操作",
        // dataIndex: 'operation',
        render: (text, record) => {
          return (
            <span>
              <Button
                className="minbutton"
                type="link"
                onClick={() => maintainshow()}
              >
                维护
              </Button>
              <Button
                className="minbutton"
                type="link"
                onClick={() => amendshow()}
              >
                修改
              </Button>
              <Popconfirm
                title="是否删除该数据?"
                okText="确认"
                cancelText="取消"
                onConfirm={() => removepupopok(record, eventKey)}
              >
                <Button className="minbutton" type="link">
                  删除
                </Button>
              </Popconfirm>
            </span>
          );
        }
      }
    ];
    const pagination = {
      current: current,
      total: total,
      defaultPageSize: 10,
      onChange: current => paginationchange(current, eventKey, values)
    };
    const { listdisplay, data } = this.state;
    //生成qrcode
    const dataurl = data.map((item, index) => {
      const http = {
        "1_1": "http://qingtian.vrplanner.cn:80/API/Platenumberindex/hh?url=",
        "1_2": "http://qingtian.vrplanner.cn:80/API/Roadnumber/hh?url=",
        "1_3": "http://qingtian.vrplanner.cn:80/API/Villagenumber/hh?url=",
        "1_4": "http://qingtian.vrplanner.cn:80/API/Scenicspot/hh?url=",
        "1_5": "http://qingtian.vrplanner.cn:80/API/Roadindex/hh?url="
      };
      return (
        <QRCode key={index} value={http[eventKey] + item.url} size={200} />
      );
    });
    return (
      <div>
        <div className="gltitle">
          <div className="titleblue" />
          <div className="titlename">{names}</div>
        </div>
        <div className="glbutton">
          <div>
            <Button type="primary" onClick={() => uploadingshow()}>
              上传信息
            </Button>
            <Search
              className="search"
              placeholder="请输入要查询名称或者类型"
              style={{ width: 240 }}
              allowClear
              onSearch={value => inputsearch(value, eventKey)}
              onChange={e => searchchange(e, eventKey)}
            />
          </div>
		  <Button type="primary" style={{position:"absolute",left:"640px"}}>
		    <a href="https://lbs.amap.com/console/show/picker" target="view_window">获取坐标点</a>
		  </Button>
          <div id="qrid">{dataurl}</div>
          <div>
            <Button
              type="primary"
              style={{ marginRight: "10px" }}
              onClick={() => this.download(eventKey)}
            >
              批量下载二维码
            </Button>
            <Radio.Group defaultValue="list" onChange={this.listandmap}>
              <Radio.Button value="list">列表</Radio.Button>
              <Radio.Button value="map">地图</Radio.Button>
            </Radio.Group>
          </div>
        </div>
        {listdisplay ? (
          eventKey == "1_2" ? (
            <Table
              rowKey="id"
              className="gltable"
              columns={columns1}
              dataSource={list}
              pagination={pagination}
              onRow={record => {
                return {
                  onClick: () => qrCodecontent(record)
                };
              }}
            />
          ) : (
            <Table
              rowKey="id"
              className="gltable"
              columns={columns}
              dataSource={list}
              pagination={pagination}
              onRow={record => {
                return {
                  onClick: () => qrCodecontent(record)
                };
              }}
            />
          )
        ) : (
          <Maps eventKey={eventKey} />
        )}
        <Popup />
        {sourcevisible ? <Soure /> : null}
        <Carouselpupop />
        {uploadvisible ? <Uploading /> : null}
        {amendvisible ? <Amend /> : null}
        {maintainvisible ? <Maintain /> : null}
      </div>
    );
  }
}
//获取redux中的内容
const mapState = state => ({
  names: state.getIn(["navigationBar", "names"]),
  eventKey: state.getIn(["navigationBar", "eventKey"]),
  county: state.getIn(["navigationBar", "county"]),
  list: state.getIn(["BasicManagement", "list"]),
  total: state.getIn(["BasicManagement", "total"]),
  current: state.getIn(["BasicManagement", "current"]),
  values: state.getIn(["BasicManagement", "values"]),
  amendvisible: state.getIn(["BasicManagement", "amendvisible"]),
  uploadvisible: state.getIn(["BasicManagement", "uploadvisible"]),
  maintainvisible: state.getIn(["BasicManagement", "maintainvisible"]),
  sourcevisible: state.getIn(["BasicManagement", "sourcevisible"])
});
const mapDispatch = dispatch => ({
  //获取内容
  loadlist(key) {
    dispatch(actionCreators.loadlist(key));
  },
  //上传页面显示
  uploadingshow() {
    dispatch(actionCreators.uploadingshow());
  },
  carouselshow() {
    dispatch(actionCreators.carouselshow());
  },
  onvisible() {
    dispatch(actionCreators.onvisible());
  },
  soureshow() {
    dispatch(actionCreators.soureshow());
  },
  qrCodecontent(record) {
    dispatch(actionCreators.qrCodecontent(record));
  },
  removepupopok(record, eventKey) {
    dispatch(actionCreators.removepupopok(record.id, eventKey));
  },
  paginationchange(current, key, values) {
    dispatch(actionCreators.paginationchange(current, key, values));
  },
  inputsearch(value, key) {
    dispatch(actionCreators.inputsearch(value, key));
  },
  searchchange(e, key) {
    dispatch(actionCreators.searchchange(e.target.value, key));
  },
  amendshow() {
    dispatch(actionCreators.amendshow());
  },
  maintainshow() {
    dispatch(actionCreators.maintainshow());
  },
  setadjunctionvisible(visible) {
    dispatch(actionCreators.setadjunctionvisible(visible));
  }
});

export default connect(mapState, mapDispatch)(BasicManagement);
