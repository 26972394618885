import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal } from "antd";
import { actionCreators } from "../store";
import Http from "../../../http";
import "../../../assets/css/content.min.css";
class Soure extends Component {
  render() {
    const { content, sourcevisible, sourcehide, eventKey } = this.props;
    return (
      <Modal
        title="语音"
        footer={null}
        visible={sourcevisible}
        onCancel={() => sourcehide()}
        destroyOnClose={true}
      >
        <div className="voice">
          <span>中文:</span>
          <audio controls="controls">
            <source
              src={Http.apiHost + "/API/" + content.mandarinVoice}
              type="audio/mpeg"
            />
          </audio>
        </div>
        <div className="voice">
          <span>英文:</span>
          <audio controls="controls">
            <source
              src={Http.apiHost + "/API/" + content.englishVoice}
              type="audio/mpeg"
            />
          </audio>
        </div>
        {eventKey == "1_3" ? null : (
          <div className="voice">
            <span>方言:</span>
            <audio controls="controls">
              <source
                src={Http.apiHost + "/API/" + content.dialectVoice}
                type="audio/mpeg"
              />
            </audio>
          </div>
        )}
      </Modal>
    );
  }
}
const mapState = state => ({
  content: state.getIn(["BasicManagement", "content"]),
  sourcevisible: state.getIn(["BasicManagement", "sourcevisible"]),
  eventKey: state.getIn(["navigationBar", "eventKey"]),
  county: state.getIn(["navigationBar", "county"])
});
const mapDispatch = dispatch => ({
  sourcehide() {
    dispatch(actionCreators.sourcehide());
  }
});

export default connect(mapState, mapDispatch)(Soure);
