import * as constants from './constants'
export const navigationBar = (key, names) => ({
    type: constants.NAVIGATIONBAR,
    eventKey: key,
    names: names
})
export const toggle = (collapsed) => ({
    type: constants.TOGGLE,
    collapsed
})
export const onselect = (item) => ({
    type: constants.ONSELECT,
    names: item.children.props.children,
    eventKey: item.eventKey,
})
export const setcounty = (name) => ({
    type: constants.SET_COUNTY,
    county: name
})