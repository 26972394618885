import React, { Component } from 'react';
import { Modal, Carousel, Icon, Button } from 'antd';
import { connect } from 'react-redux'
import { actionCreators } from '../store'
import Http from "../../../http";
import '../../../assets/css/content.min.css'
class Carouselpupop extends Component {
    handlePrev = () => {
        this.refs.img.prev(); //ref = img
    }
    handleNext = () => {
        this.refs.img.next();
    }
    render() {
        const { carouselvisible, carouselhide, content } = this.props
        const url = content.pictureUrl
		console.log(this.props)
		console.log(content)
        return (
            <Modal title='图片' className='carouselmadal' width='600px' footer={null} visible={carouselvisible} onCancel={() => carouselhide()}>
                <Icon type="left" className='icon' onClick={this.handlePrev} />
                {
                    content.length !== 0 ?
                        <Carousel className='carousel' ref='img'>
                            {
								url ? 
                                url.map((item, index) => {
                                    return (
                                        <div key={index} >
                                            <img src={Http.apiHost + '/API' + item} alt="" />
                                        </div>

                                    )
                                }) : ''
                            }
                        </Carousel>
                        : ''
                }
                <Icon type="right" className='icon' onClick={this.handleNext} />
            </Modal>
        );
    }
}
const mapState = (state) => ({
    carouselvisible: state.getIn(['BasicManagement', 'carouselvisible']),
    content: state.getIn(['BasicManagement', 'content']),
})
const mapDispatch = (dispatch) => ({
    carouselhide() {
        dispatch(actionCreators.carouselhide())
    }
})
export default connect(mapState, mapDispatch)(Carouselpupop);