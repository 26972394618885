import * as constants from './constants'
import { fromJS } from 'immutable'
const defaultState = fromJS({
    uploadvisible: false,
    compilevisible: false,
    current: 1,
    size: "10"
})
export default (state = defaultState, action) => {
    switch (action.type) {
        case constants.UPLOADING_SHOW:
            return state.set('uploadvisible', true)
        case constants.UPLOADING_HIDE:
            return state.set('uploadvisible', false)
        case constants.SET_COUNT:
            return state.set('count', action.count)
        case constants.SET_LIST:
            return state.set('list', action.list)
        case constants.REFRESH_CURRENT:
            return state.set('current', 1)
        case constants.VALUES:
            return state.set('values', action.values)
        case constants.CURRENTS:
            return state.set('current', action.current)
        case constants.COMPILE_SHOW:
            return state.set('compilevisible', true)
        case constants.COMPILE_HIDE:
            return state.set('compilevisible', false)
        case constants.CONTENT:
            return state.set('content', action.content)
        default:
            return state
    }

}