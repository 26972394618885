import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Button, Input, Table } from 'antd'
import { actionCreators } from './store'
import '../../assets/css/content.min.css'
const { Search } = Input;
class FeedbackList extends Component {
    componentDidMount() {
        this.props.getfeedbacklist()
    }
    render() {
        const columns = [
            {
                title: '反馈道路',
                dataIndex: 'feedbackPath',
            },
            {
                title: '反馈类型',
                dataIndex: 'feedbackType',
            },
            {
                title: '详细内容',
                dataIndex: 'details',
            },
            // {
            //     title: '是否处理',
            //     dataIndex: 'handle',
            // },
            {
                title: '提交时间',
                dataIndex: 'submiTime',
            },
        ]
        const { names, list, total, current, value, paginationchange, inputsearch, searchchange } = this.props
        const pagination = {
            current: current,
            total: total,
            defaultPageSize: 10,
            onChange: (current) => paginationchange(current, value)
        }
        return (
            <div>
                <div className='gltitle'>
                    <div className='titleblue' />
                    <div className='titlename'>
                        {names}
                    </div>
                </div>
                <div>
                    <Search
                        className='search'
                        placeholder="请输入要查询名称"
                        style={{ width: 240 }}
                        allowClear
                        onSearch={(value) => inputsearch(value)}
                        onChange={(e) => searchchange(e)}
                    />
                </div>
                <Table rowKey='id' className='gltable' columns={columns} dataSource={list} pagination={pagination} />
            </div>
        );
    }
}
const mapState = (state) => ({
    names: state.getIn(['navigationBar', 'names']),
    list: state.getIn(['feedbackList', 'list']),
    total: state.getIn(['feedbackList', 'total']),
    current: state.getIn(['feedbackList', 'current']),
    value: state.getIn(['feedbackList', 'value']),
})
const mapDispatch = (dispatch) => ({
    getfeedbacklist() {
        dispatch(actionCreators.getfeedbacklist())
    },
    paginationchange(current, value) {
        dispatch(actionCreators.paginationchange(current, value))
    },
    inputsearch(value) {
        dispatch(actionCreators.inputsearch(value))
    },
    searchchange(e) {
        dispatch(actionCreators.searchchange(e.target.value))
    }
})

export default connect(mapState, mapDispatch)(FeedbackList);