import React, { Component } from "react";
import { Map, Marker } from "react-amap";
import { connect } from "react-redux";
import { Icon } from "antd";
import "../../assets/css/content.min.css";
import PlateNumber from "../../assets/imgs/icon/门牌.png";
import RoadNumber from "../../assets/imgs/icon/路牌.png";
import VillageNumber from "../../assets/imgs/icon/村牌.png";
import Scenicspot from "../../assets/imgs/icon/景区.png";
import Road from "../../assets/imgs/icon/道路.png";
import { actionCreators } from "../Map/store";
import axios from "axios";
import Http from "../../http";
const mapKey = "a6c0b1d91f80cff868c940ee8021b60c";
class Maps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lonandlat: [],
      visible: false
    };
  }
  componentWillMount() {
    const eventKey = this.props.eventKey;
    if (eventKey == "1_1") {
      axios.get(Http.apiHost + "/API/PlateNumber/getLonAndLat").then(res => {
        const result = res.data.data;
        this.setState({
          lonandlat: result
        });
      });
    } else if (eventKey == "1_2") {
      axios.get(Http.apiHost + "/API/RoadNumber/getLonAndLat").then(res => {
        const result = res.data.data;
        this.setState({
          lonandlat: result
        });
      });
    } else if (eventKey == "1_3") {
      axios.get(Http.apiHost + "/API/VillageNumber/getLonAndLat").then(res => {
        const result = res.data.data;
        this.setState({
          lonandlat: result
        });
      });
    } else if (eventKey == "1_4") {
      axios.get(Http.apiHost + "/API/Scenicspot/getLonAndLat").then(res => {
        const result = res.data.data;
        this.setState({
          lonandlat: result
        });
      });
    } else if (eventKey == "1_5") {
      axios.get(Http.apiHost + "/API/Road/getLonAndLat").then(res => {
        const result = res.data.data;
        this.setState({
          lonandlat: result
        });
      });
    }
  }
  particularshide = () => {
    this.setState({
      visible: false
    });
  };
  particularsshow = (e, eventKey) => {
    const lng = e.target.B.position.lng;
    const lat = e.target.B.position.lat;
    const num1 = lng.toFixed(6);
    const num2 = lat.toFixed(6);
    const position = [num1, num2];
    if (eventKey == "1_1") {
      axios
        .get(Http.apiHost + "/API/PlateNumber/getList2?key=" + position)
        .then(res => {
          const result = res.data;
          this.setState({
            visible: true,
            particularsdata: result
          });
        });
    } else if (eventKey == "1_2") {
      axios
        .get(Http.apiHost + "/API/RoadNumber/getList2?key=" + position)
        .then(res => {
          const result = res.data;
          this.setState({
            visible: true,
            particularsdata: result
          });
        });
    } else if (eventKey == "1_3") {
      axios
        .get(Http.apiHost + "/API/VillageNumber/getList2?key=" + position)
        .then(res => {
          const result = res.data;
          this.setState({
            visible: true,
            particularsdata: result
          });
        });
    } else if (eventKey == "1_4") {
      axios
        .get(Http.apiHost + "/API/Scenicspot/getList2?key=" + position)
        .then(res => {
          const result = res.data;
          this.setState({
            visible: true,
            particularsdata: result
          });
        });
    } else if (eventKey == "1_5") {
      axios
        .get(Http.apiHost + "/API/Road/getList2?key=" + position)
        .then(res => {
          const result = res.data;
          this.setState({
            visible: true,
            particularsdata: result
          });
        });
    }
  };
  render() {
    const { eventKey } = this.props;
    const { particularsdata, lonandlat, visible } = this.state;
    const events = {
      click: e => {
        this.particularsshow(e, eventKey);
      }
    };

    const aaa = {
      "1_1": "http://qingtian.vrplanner.cn:80/API/Platenumberindex/hh?url=",
      "1_2": "http://qingtian.vrplanner.cn:80/API/Roadnumber/hh?url=",
      "1_3": "http://qingtian.vrplanner.cn:80/API/Villagenumber/hh?url=",
      "1_4": "http://qingtian.vrplanner.cn:80/API/Scenicspot/hh?url=",
      "1_5": "http://qingtian.vrplanner.cn:80/API/Roadindex/hh?url="
    };
    return (
      <div className="map">
        <Map amapkey={mapKey} center={[120.289614, 28.139023]} zoom={15}>
          {lonandlat.map((item, index) => {
            if (eventKey == "1_1") {
              return (
                <Marker
                  key={index}
                  position={[item.lng, item.lat]}
                  events={events}
                  icon={PlateNumber}
                />
              );
            } else if (eventKey == "1_2") {
              return (
                <Marker
                  key={index}
                  position={[item.lng, item.lat]}
                  events={events}
                  icon={RoadNumber}
                />
              );
            } else if (eventKey == "1_3") {
              return (
                <Marker
                  key={index}
                  position={[item.lng, item.lat]}
                  events={events}
                  icon={VillageNumber}
                />
              );
            } else if (eventKey == "1_4") {
              return (
                <Marker
                  key={index}
                  position={[item.lng, item.lat]}
                  events={events}
                  icon={Scenicspot}
                />
              );
            } else if (eventKey == "1_5") {
              return (
                <Marker
                  key={index}
                  position={[item.lng, item.lat]}
                  events={events}
                  icon={Road}
                />
              );
            }
          })}
        </Map>
        {visible ? (
          <div className="particulars">
            <div className="head">
              <div className="headleft">
                <Icon type="environment" theme="filled" className="icon" />
                <span>{particularsdata.name}</span>
              </div>
              <div className="headright">
                <Icon
                  type="close"
                  className="icon"
                  onClick={this.particularshide}
                />
              </div>
            </div>
            <div className="body">
              <div className="img compoents">
                <div className="title">
                  <div>图片</div>
                </div>
                <div className="imgbody">
                  {particularsdata.pictureUrl.map((item, index) => {
                    return (
                      <img
                        key={index}
                        src={Http.apiHost + "/API" + item}
                        alt=""
                      />
                    );
                  })}
                </div>
              </div>
              <div className="voice1 compoents">
                <div className="title">
                  <div>语音</div>
                </div>
                <div className="voicebody">
                  <div>
                    <span>普通话:</span>
                    <audio controls="controls">
                      <source
                        src={
                          Http.apiHost + "/API" + particularsdata.mandarinVoice
                        }
                        type="audio/mpeg"
                      />
                    </audio>
                  </div>
                  <div>
                    <span>英语:</span>
                    <audio controls="controls">
                      <source
                        src={
                          Http.apiHost + "/API" + particularsdata.englishVoice
                        }
                        type="audio/mpeg"
                      />
                    </audio>
                  </div>
                  {eventKey == "1_3" ? null : (
                    <div>
                      <span>方言:</span>
                      <audio controls="controls">
                        <source
                          src={
                            Http.apiHost + "/API" + particularsdata.dialectVoice
                          }
                          type="audio/mpeg"
                        />
                      </audio>
                    </div>
                  )}
                </div>
              </div>
              <div className="url compoents">
                <div className="title">
                  <div>二维码</div>
                </div>
                <div className="urlbody">
                  <img
                    src={
                      "https://api.isoyu.com/qr/?m=0&e=L&p=10&url=" +
                      aaa[eventKey] +
                      particularsdata.url
                    }
                    alt=""
                  />
                </div>
              </div>
              <div className="mainte compoents">
                <div className="title">
                  <div>维护情况</div>
                </div>
                <div className="maintebody">
                  <div className="mainteleft">
                    <div>尺寸：{particularsdata.size}(m)</div>
                    <div>设立年份：{particularsdata.yearOfEsent}</div>
                    <div>维护单位：{particularsdata.mainteUnit}</div>
                    <div>维护次数：{particularsdata.numofMaint}次</div>
                    <div>维护费用：{particularsdata.maintCost}元</div>
                  </div>
                  <div className="mainteright">
                    <div>材质：{particularsdata.textOfMater}</div>
                    <div>维护期限：{particularsdata.maintPeriod}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
const mapState = state => ({
  eventKey: state.getIn(["navigationBar", "eventKey"]),
  county: state.getIn(["navigationBar", "county"])
});
const mapDispatch = dispatch => ({});

export default connect(mapState, mapDispatch)(Maps);
