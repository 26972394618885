import React, { Component } from "react";
import { Modal, Button } from "antd";
import { connect } from "react-redux";
import { actionCreators } from "../store";
import Http from "../../../http";
const QRCode = require("qrcode-react");
class Pupop extends Component {
  download = name => {
    let Qr = document.getElementById("qrid2").children[0];
    // let Qr = document.getElementById('qrid');
    let a = document.createElement("a");
    let image = new Image();
    let evt = document.createEvent("HTMLEvents");
    evt.initEvent("click", true, true);
    image.src = Qr.toDataURL("image/png");
    a.href = image.src;
    a.download = name;
    a.dispatchEvent(
      new MouseEvent("click", { bubbles: true, cancelable: true, view: window })
    );
  };
  render() {
    const { visible, qrCodevisible, content, eventKey } = this.props;
    const aaa = {
      "1_1": "http://qingtian.vrplanner.cn:80/API/Platenumberindex/hh?url=",
      "1_2": "http://qingtian.vrplanner.cn:80/API/Roadnumber/hh?url=",
      "1_3": "http://qingtian.vrplanner.cn:80/API/Villagenumber/hh?url=",
      "1_4": "http://qingtian.vrplanner.cn:80/API/Scenicspot/hh?url=",
      "1_5": "http://qingtian.vrplanner.cn:80/API/Roadindex/hh?url="
    };
    return (
      <Modal
        title="二维码"
        footer={null}
        visible={visible}
        onCancel={() => qrCodevisible()}
      >
        <div className="pupop" id="qrid2">
          <QRCode value={aaa[eventKey] + content.url} size={200} />
          <div className="download">
            <Button type="primary" onClick={() => this.download(content.name)}>
              点击下载
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}
const mapState = state => ({
  visible: state.getIn(["BasicManagement", "visible"]),
  content: state.getIn(["BasicManagement", "content"]),
  eventKey: state.getIn(["navigationBar", "eventKey"]),
  county: state.getIn(["navigationBar", "county"])
});
const mapDispatch = dispatch => ({
  //关闭页面触发
  qrCodevisible() {
    dispatch(actionCreators.qrCodevisible());
  }
});
export default connect(mapState, mapDispatch)(Pupop);
