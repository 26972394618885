import React, { Component } from 'react';
import './footer.css'

class Footer extends Component {
    render() {
        return (
            <div className="footer-wrap">
                <a href="https://beian.miit.gov.cn">浙ICP备20022480号</a>
            </div>
        );
    }
}

export default Footer;