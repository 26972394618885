import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, Form, Input, Upload, Button, Icon, message } from "antd";
import { actionCreators } from "../store";
import "../../../assets/css/content.min.css";
import axios from "axios";
import Http from "../../../http";
const { TextArea } = Input;
class Amend extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileListV1: [],
      fileListV2: [],
      fileListV3: [],
      fileListI: [],
      loading: false
    };
  }
  updata1 = (data, eventKey) => {
    const key = {
      "1_1": "PlateNumber",
      "1_2": "RoadNumber",
      "1_3": "VillageNumber",
      "1_4": "Scenicspot",
      "1_5": "Road"
    };
    axios
      .post(Http.apiHost + "/API/" + key[eventKey] + "/update", data)
      .then(res => {
        message.success("修改成功");
        this.setState({
          loading: false
        });
      });
  };
  updata2 = (data3, id, eventKey) => {
    const key = {
      "1_1": "platenumberId",
      "1_2": "roadnumberId",
      "1_3": "villagenumberId",
      "1_4": "scenicspotId",
      "1_5": "roadId"
    };
    data3.append(key[eventKey], id);
    axios
      .post(Http.apiHost + "/API/Voice/updateMandarin", data3)
      .then(res => {});
  };
  updata3 = (data5, id, eventKey) => {
    const key = {
      "1_1": "platenumberId",
      "1_2": "roadnumberId",
      "1_3": "villagenumberId",
      "1_4": "scenicspotId",
      "1_5": "roadId"
    };
    data5.append(key[eventKey], id);
    axios
      .post(Http.apiHost + "/API/Audio/updateDialect", data5)
      .then(res => {});
  };
  updata4 = (data2, id, eventKey) => {
    const key = {
      "1_1": "platenumberId",
      "1_2": "roadnumberId",
      "1_3": "villagenumberId",
      "1_4": "scenicspotId",
      "1_5": "roadId"
    };
    data2.append(key[eventKey], id);
    axios.post(Http.apiHost + "/API/Picture/update", data2).then(res => {});
  };
  updata5 = (data4, id, eventKey) => {
    const key = {
      "1_1": "platenumberId",
      "1_2": "roadnumberId",
      "1_3": "villagenumberId",
      "1_4": "scenicspotId",
      "1_5": "roadId"
    };
    data4.append(key[eventKey], id);
    axios
      .post(Http.apiHost + "/API/Audio/updateEnglish", data4)
      .then(res => {});
  };
  syupdata1 = data => {
    axios
      .post(Http.apiHost + "/API/VillageNumber/updateAndMandarin", data)
      .then(res => {
        message.success("修改成功");
        this.setState({
          loading: false
        });
      });
  };
  syupdata2 = (sydata, id) => {
    sydata.append("villagenumberId", id);
    axios
      .post(Http.apiHost + "/API/Audio/updateMandarin", sydata)
      .then(res => {});
  };
  handleSubmit = (e, content, eventKey, county) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const id = content.id;
        const files = this.state.fileListI;
        const file1 = this.state.fileListV1;
        const file2 = this.state.fileListV2;
        const file3 = this.state.fileListV3;
        values.id = id;
        const uploadvoice1 = values.uploadvoice1;
        delete values.uploadvoice1;
        delete values.uploadvoice2;
        delete values.uploadvoice3;
        delete values.uploadimg;
        const data = new FormData();
        const data2 = new FormData();
        const data3 = new FormData();
        const data4 = new FormData();
        const data5 = new FormData();
        const songyangdata = new FormData();
        for (let i in values) {
          data.append(i, values[i]);
        }
        files.forEach(file => {
          data2.append("files", file);
        });
        data3.append("data", uploadvoice1);
        if (file1 !== "" || file1 !== undefined) {
          file1.forEach(file => {
            songyangdata.append("file", file);
          });
        }
        if (file2 !== "" || file2 !== undefined) {
          file2.forEach(file => {
            data4.append("file", file);
          });
        }
        if (file3 !== "" || file3 !== undefined) {
          file3.forEach(file => {
            data5.append("file", file);
          });
        }
        this.setState({
          loading: true
        });

        if (files.length) {
          this.updata4(data2, id, eventKey);
        }
        if (file2.length) {
          this.updata5(data4, id, eventKey);
        }
        if (file3.length) {
          this.updata3(data5, id, eventKey);
        }
        this.updata1(data, eventKey);
        this.updata2(data3, id, eventKey);
      }
    });
  };
  render() {
    const {
      amendvisible,
      eventKey,
      content,
      current,
      amendandhide,
      county
    } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { fileListV1, fileListV2, fileListV3, fileListI } = this.state;
    const uploadvoice1 = {
      onRemove: file => {
        this.setState(state => {
          const index = state.fileListV1.indexOf(file);
          const newFileList = state.fileListV1.slice();
          newFileList.splice(index, 1);
          return {
            fileListV1: newFileList
          };
        });
      },
      beforeUpload: file => {
        this.setState(state => ({
          fileListV1: [...state.fileListV1, file]
        }));
        return false;
      },
      fileListV1
    };
    const uploadvoice2 = {
      onRemove: file => {
        this.setState(state => {
          const index = state.fileListV2.indexOf(file);
          const newFileList = state.fileListV2.slice();
          newFileList.splice(index, 1);
          return {
            fileListV2: newFileList
          };
        });
      },
      beforeUpload: file => {
        this.setState(state => ({
          fileListV2: [...state.fileListV2, file]
        }));
        return false;
      },
      fileListV2
    };
    const uploadvoice3 = {
      onRemove: file => {
        this.setState(state => {
          const index = state.fileListV3.indexOf(file);
          const newFileList = state.fileListV3.slice();
          newFileList.splice(index, 1);
          return {
            fileListV3: newFileList
          };
        });
      },
      beforeUpload: file => {
        this.setState(state => ({
          fileListV3: [...state.fileListV3, file]
        }));
        return false;
      },
      fileListV3
    };
    const uploadimg = {
      onRemove: file => {
        this.setState(state => {
          const index = state.fileListI.indexOf(file);
          const newFileList = state.fileListI.slice();
          newFileList.splice(index, 1);
          return {
            fileListI: newFileList
          };
        });
      },
      beforeUpload: file => {
        this.setState(state => ({
          fileListI: [...state.fileListI, file]
        }));
        return false;
      },
      fileListI
    };
    return (
      <Modal
        title="修改信息"
        className="uploadmodal"
        footer={null}
        visible={amendvisible}
        onCancel={() => amendandhide(eventKey, current)}
        destroyOnClose={true}
      >
        <Form
          className="upload"
          onSubmit={e => this.handleSubmit(e, content, eventKey, county)}
        >
          {eventKey == "1_2" ? (
            <Form.Item className="uploaditem" label="路牌编号">
              {getFieldDecorator("roadnum", {
                initialValue: content.roadNum
              })(
                <Input className="uploadinput" placeholder={content.roadNum} />
              )}
            </Form.Item>
          ) : null}
          <Form.Item className="uploaditem" label="名称">
            {getFieldDecorator("name", {
              initialValue: content.name
            })(<Input className="uploadinput" />)}
          </Form.Item>
          <Form.Item className="uploaditem" label="经度">
            {getFieldDecorator("lon", {
              initialValue: content.lon
            })(<Input className="uploadinput" placeholder={content.lon} />)}
          </Form.Item>
          <Form.Item className="uploaditem" label="纬度">
            {getFieldDecorator("lat", {
              initialValue: content.lat
            })(<Input className="uploadinput" placeholder={content.lat} />)}
          </Form.Item>
		  <Form.Item className="uploaditem" label="路线点">
		    {getFieldDecorator("roadline", {
		      initialValue: content.roadline
		    })(<Input className="uploadinput" placeholder="例子:[120.287989, 28.138526], [120.286905, 28.13934]" />)}
		  </Form.Item>
          <Form.Item className="uploaditem" label="类型">
            {getFieldDecorator("type", {
              initialValue: content.type
            })(<Input className="uploadinput" placeholder={content.type} />)}
          </Form.Item>
          <Form.Item className="uploaditem" label="材质">
            {getFieldDecorator("textofmater", {
              initialValue: content.textOfMater
            })(
              <Input
                className="uploadinput"
                placeholder={content.textOfMater}
              />
            )}
          </Form.Item>
          <Form.Item className="uploaditem" label="尺寸">
            {getFieldDecorator("size", {
              initialValue: content.size
            })(<Input className="uploadinput" placeholder={content.size} />)}
          </Form.Item>
          <Form.Item className="uploaditem" label="设立年份">
            {getFieldDecorator("yearofesent", {
              initialValue: content.yearOfEsent
            })(
              <Input
                className="uploadinput"
                placeholder={content.yearOfEsent}
              />
            )}
          </Form.Item>
          <Form.Item className="uploaditem" label="维护单位">
            {getFieldDecorator("mainteunit", {
              initialValue: content.mainteUnit
            })(
              <Input className="uploadinput" placeholder={content.mainteUnit} />
            )}
          </Form.Item>
          <Form.Item className="uploaditem" label="维护次数">
            {getFieldDecorator("numofmaint", {
              initialValue: content.numofMaint
            })(
              <Input className="uploadinput" placeholder={content.numofMaint} />
            )}
          </Form.Item>
          <Form.Item className="uploaditem" label="维护期限">
            {getFieldDecorator("maintperiod", {
              initialValue: content.maintPeriod
            })(
              <Input
                className="uploadinput"
                placeholder={content.maintPeriod}
              />
            )}
          </Form.Item>
          <Form.Item className="uploaditem" label="维护费用">
            {getFieldDecorator("maintcost", {
              initialValue: content.maintCost
            })(
              <Input className="uploadinput" placeholder={content.maintCost} />
            )}
          </Form.Item>
          <Form.Item className="uploaditem" label="维护台账">
            {getFieldDecorator("maintaccounts", {
              initialValue: content.maintAccounts
            })(
              <Input
                className="uploadinput"
                placeholder={content.maintAccounts}
              />
            )}
          </Form.Item>
          <Form.Item className="uploaditem" label="语音(中文)">
            {getFieldDecorator("uploadvoice1", {
              initialValue: content.mandarinWord
            })(
              <TextArea
                autosize={{ minRows: 2, maxRows: 6 }}
                className="uploadinput"
                placeholder={content.mandarinWord}
              />
            )}
          </Form.Item>
          <Form.Item className="uploaditem" label="英文内容">
            {getFieldDecorator("englishword", {
              initialValue: content.englishWord
            })(
              <TextArea
                autosize={{ minRows: 2, maxRows: 6 }}
                className="uploadinput"
                placeholder={content.englishWord}
              />
            )}
          </Form.Item>
          <Form.Item className="uploaditem" label="语音(英文)">
            {getFieldDecorator(
              "uploadvoice2",
              {}
            )(
              <Upload {...uploadvoice2} listType="text">
                {fileListV2.length < 1 ? (
                  <Button>
                    <Icon type="upload" /> 上传
                  </Button>
                ) : null}
              </Upload>
            )}
          </Form.Item>
          {eventKey == "1_3" ? null : (
            <Form.Item className="uploaditem" label="语音(方言)">
              {getFieldDecorator(
                "uploadvoice3",
                {}
              )(
                <Upload {...uploadvoice3} listType="text">
                  {fileListV3.length < 1 ? (
                    <Button>
                      <Icon type="upload" /> 上传
                    </Button>
                  ) : null}
                </Upload>
              )}
            </Form.Item>
          )}
          <Form.Item className="uploaditem" label="图片">
            {getFieldDecorator(
              "uploadimg",
              {}
            )(
              <Upload {...uploadimg} listType="text">
                {fileListI.length < 3 ? (
                  <Button>
                    <Icon type="upload" /> 上传
                  </Button>
                ) : null}
              </Upload>
            )}
          </Form.Item>
          <Form.Item style={{ textAlign: "center" }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={this.state.loading}
            >
              修改
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

const mapState = state => ({
  content: state.getIn(["BasicManagement", "content"]),
  amendvisible: state.getIn(["BasicManagement", "amendvisible"]),
  eventKey: state.getIn(["navigationBar", "eventKey"]),
  current: state.getIn(["BasicManagement", "current"]),
  county: state.getIn(["navigationBar", "county"])
});
const mapDispatch = dispatch => ({
  amendhide() {
    dispatch(actionCreators.amendhide());
  },
  amendandhide(key, current) {
    dispatch(actionCreators.amendandhide(key, current));
  }
});

const WrappedNormalLoginForm = Form.create()(Amend);
export default connect(mapState, mapDispatch)(WrappedNormalLoginForm);
