import { combineReducers } from "redux-immutable";
import { reducer as navigationBar } from "../compoents/navigationBar/store";
import { reducer as BasicManagement } from "../compoents/BasicManagement/store/";
import { reducer as Maps } from "../compoents/Map/store/";
import { reducer as maintain } from "../compoents/maintain/store";
import { reducer as statistics1 } from "../compoents/statistics1/store";
import { reducer as statistics2 } from "../compoents/statistics2/store";
import { reducer as feedbackList } from "../compoents/feedbackList/store";
import { reducer as contentManagement } from "../compoents/contentManagement/store";
import { reducer as MassivePoint } from "../compoents/MassivePoint/store";

const reducer = combineReducers({
  navigationBar: navigationBar,
  BasicManagement: BasicManagement,
  Maps: Maps,
  maintain: maintain,
  statistics1: statistics1,
  statistics2: statistics2,
  feedbackList: feedbackList,
  contentManagement: contentManagement,
  MassivePoint: MassivePoint
});

export default reducer;
