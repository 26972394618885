import React, { Component } from "react";
import { Map, Marker } from "react-amap";
import { connect } from "react-redux";
import PlateNumber from "../../assets/imgs/icon/门牌.png";
import RoadNumber from "../../assets/imgs/icon/路牌.png";
import VillageNumber from "../../assets/imgs/icon/村牌.png";
import Scenicspot from "../../assets/imgs/icon/景区.png";
import { actionCreators } from "./store";
import "../../assets/css/content.min.css";
const mapKey = "a6c0b1d91f80cff868c940ee8021b60c";
class MassivePoint extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.props.getmapcontent();
  }
  render() {
    const { names, content } = this.props;
    const aaa = {
      platenumber: PlateNumber,
      roadnumber: RoadNumber,
      villagenumber: VillageNumber,
      scenicspot: Scenicspot
    };
    return (
      <div>
        <div className="gltitle">
          <div className="titleblue" />
          <div className="titlename">{names}</div>
        </div>
        <div className="map">
          <Map amapkey={mapKey} center={[120.289614, 28.139023]} zoom={13}>
            {content.map((item, index) => {
              return (
                <Marker
                  key={index}
                  position={[item.lon, item.lat]}
                  label={{
                    content:
                      item.name +
                      "/" +
                      item.monthlyvisits +
                      "/" +
                      item.yearlyvisits,
                    direction: "top"
                  }}
                  icon={aaa[item.type]}
                />
              );
            })}
          </Map>
          <div className="hint">
            <div>
              <img src={PlateNumber} />
              <div>门牌</div>
            </div>
            <div>
              <img src={RoadNumber} />
              <div>路牌</div>
            </div>
            <div>
              <img src={VillageNumber} />
              <div>村牌</div>
            </div>
            <div>
              <img src={Scenicspot} />
              <div>景区</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapState = state => ({
  names: state.getIn(["navigationBar", "names"]),
  content: state.getIn(["MassivePoint", "content"])
});

const mapDispatch = dispatch => ({
  //获取点位信息
  getmapcontent() {
    dispatch(actionCreators.getmapcontent());
  }
});
export default connect(mapState, mapDispatch)(MassivePoint);
