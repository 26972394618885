// import React from 'react';
import React, { Component } from "react";
import { Form, Input, Button, message, Icon } from "antd";
import "../../assets/css/content.min.css";
import { Link } from "react-router-dom";
import axios from "axios";
import Http from "../../http";
class ChangePassword extends Component {
	constructor(props) {
	  super(props);
	  this.state = {};
	}
	handleSubmit = e => {
	  e.preventDefault();
	  this.props.form.validateFields((err, values) => {
	    if (!err) {
	      const data = new FormData();
	      for (let i in values) {
	        data.append(i, values[i]);
	      }
	      axios.defaults.withCredentials = true;
	      axios.post(Http.apiHost + "/API/User/changepwd", data).then(res => {
	        const result = res.data.data;
	        const messages = res.data.message;
			console.log(res.data.data)
	        if (result == null) {
	          message.error(messages);
	        } else {
	          message.success("修改成功");
	          sessionStorage.setItem("name", result.userName);
	          sessionStorage.setItem("level", result.level);
	          sessionStorage.setItem("load", "1");
	          sessionStorage.setItem("county", result.county);
	          this.props.history.push({ pathname: "/BasicManagement/1" });
			  
	        }
	      });
	    }
	  });
	};
	render() {
	  const { getFieldDecorator } = this.props.form;
	  return (
	    <div
	      className="register"
	      // style={{ background: `url(${background[url]}) no-repeat` }}
	    >
	      <Form className="registerform" onSubmit={e => this.handleSubmit(e)}>
	        <Form.Item>
	          <div className="registerdiv">修改密碼</div>
	        </Form.Item>
	        <Form.Item className="registeritem">
	          {getFieldDecorator("oldpassword", {
	            rules: [{ required: true, message: "请输入旧密码!" }]
	          })(
	            <Input
	              placeholder="旧密码"
	              className="registerinput"
	            />
	          )}
	        </Form.Item>
	        <Form.Item className="registeritem">
	          {getFieldDecorator("newpassword", {
	            rules: [{ required: true, message: "请输入新密码!" }]
	          })(
	            <Input
	              type="password"
	              placeholder="新密码"
	              className="registerinput"
	            />
	          )}
	        </Form.Item>
	        <Form.Item style={{ textAlign: "center", marginTop: "20px" }}>
	          <Button type="primary" htmlType="submit">
				确定
	          </Button>
			  <Button type="primary" htmlType="submit" style={{marginLeft: "20px" }}>
			  	<a href="/BasicManagement/1">退出</a>
			  </Button>
	        </Form.Item>
	      </Form>
	    </div>
	  );
	}
}
export default Form.create()(ChangePassword);