export const LOADLISTS = "BasicManagement/LOADLISTS";
export const LOADTOTALS = "BasicManagement/LOADTOTALS";
export const SOURCESHOW = "BasicManagement/SOURESHOW";
export const SOURCEHIDE = "BasicManagement/SOURCEHIDE";
export const ONVISIBLE = "BasicManagement/ONVISIBLE";
export const QRCODEVISIBLE = "BasicManagement/QRCODEVISIBLE";
export const QRCODECONTENT = "BasicManagement/QRCODECONTENT";
export const REMOVESHOW = "BasicManagement/REMOVESHOW";
export const REMOVEPUPOPOK = "BasicManagement/REMOVEPUPOPOK";
export const REMOVEPUPOPCANCEL = "BasicManagement/REMOVEPUPOPCANCEL";
export const UPLOADING_SHOW = "BasicManagement/UPLOADING_SHOW";
export const UPLOADING_HIDE = "BasicManagement/UPLOADING_HIDE";
export const CAROUSEL_SHOW = "BasicManagement/CAROUSEL_SHOW";
export const CAROUSEL_HIDE = "BasicManagement/CAROUSEL_HIDE";
export const CURRENTS = "BasicManagement/CURRENTS";
export const VALUES = "BasicManagement/VALUES";
export const INITCURRENTS = "BasicManagement/INITCURRENTS";
export const AMEND_SHOW = "BasicManagement/AMEND_SHOW";
export const AMEND_HIDE = "BasicManagement/AMEND_HIDE";
export const MAINTAIN_SHOW = "BasicManagement/MAINTAIN_SHOW";
export const MAINTAIN_HIDE = "BasicManagement/MAINTAIN_HIDE";
