import * as constants from './constants'
import axios from 'axios'
import Http from '../../../http'
export const setlist = (item) => ({
    type: constants.SET_LIST,
    list: item
})
export const settotal = (item) => ({
    type: constants.SET_TOTAL,
    total: item
})
export const setcurrent = (item) => ({
    type: constants.SET_CURRENT,
    current: item
})
export const setinputvalue = (item) => ({
    type: constants.SET_INPUT_VALUE,
    value: item
})
export const loadlist = (key) => {
    return (dispatch) => {
        if (key == '3_2') {
            axios.get(Http.apiHost + '/API/PlateNumber/getPlateNumCountByName?page=1&size=10').then((res) => {
                const result = res.data.data
                dispatch(setlist(result.list))
                dispatch(settotal(result.count))
            })
        }
        else {
            axios.get(Http.apiHost + '/API/RoadNumber/getRoadCountByName?page=1&size=10').then((res) => {
                const result = res.data.data
                dispatch(setlist(result.list))
                dispatch(settotal(result.count))
            })
        }
    }
}
export const paginationchange = (current, value, key) => {
    return (dispatch) => {
        if (value == undefined || value == '') {
            if (key == '3_2') {
                axios.get(Http.apiHost + '/API/PlateNumber/getPlateNumCountByName?page=' + current + '&size=10').then((res) => {
                    const result = res.data.data
                    dispatch(setlist(result.list))
                    dispatch(settotal(result.count))
                    dispatch(setcurrent(current))
                })
            }
            else {
                axios.get(Http.apiHost + '/API/RoadNumber/getRoadCountByName?page=' + current + '&size=10').then((res) => {
                    const result = res.data.data
                    dispatch(setlist(result.list))
                    dispatch(settotal(result.count))
                    dispatch(setcurrent(current))
                })
            }
        }
        else {
            if (key == '3_2') {
                axios.get(Http.apiHost + '/API/PlateNumber/getPlateNumCountByName?page=' + current + '&size=10&name=' + value).then((res) => {
                    const result = res.data.data
                    dispatch(setlist(result.list))
                    dispatch(settotal(result.count))
                    dispatch(setcurrent(current))
                })
            }
            else {
                axios.get(Http.apiHost + '/API/RoadNumber/getRoadCountByName?page=' + current + '&size=10&name=' + value).then((res) => {
                    const result = res.data.data
                    dispatch(setlist(result.list))
                    dispatch(settotal(result.count))
                    dispatch(setcurrent(current))
                })
            }
        }
    }
}
export const inputsearch = (value, key) => {
    return (dispatch) => {
        if (key == '3_2') {
            axios.get(Http.apiHost + '/API/PlateNumber/getPlateNumCountByName?page=1&size=10&name=' + value).then((res) => {
                const result = res.data.data
                dispatch(setlist(result.list))
                dispatch(settotal(result.count))
                dispatch(setinputvalue(value))
                dispatch(setcurrent(1))
            })
        }
        else {
            axios.get(Http.apiHost + '/API/RoadNumber/getRoadCountByName?page=1&size=10&name=' + value).then((res) => {
                const result = res.data.data
                dispatch(setlist(result.list))
                dispatch(settotal(result.count))
                dispatch(setinputvalue(value))
                dispatch(setcurrent(1))
            })
        }
    }
}
export const searchchange = (e, key) => {
    return (dispatch) => {
        if (e == '') {
            if (key == '3_2') {
                axios.get(Http.apiHost + '/API/PlateNumber/getPlateNumCountByName?page=1&size=10').then((res) => {
                    const result = res.data.data
                    dispatch(setlist(result.list))
                    dispatch(settotal(result.count))
                    dispatch(setinputvalue())
                    dispatch(setcurrent(1))
                })
            }
            else {
                axios.get(Http.apiHost + '/API/RoadNumber/getRoadCountByName?page=1&size=10').then((res) => {
                    const result = res.data.data
                    dispatch(setlist(result.list))
                    dispatch(settotal(result.count))
                    dispatch(setinputvalue())
                    dispatch(setcurrent(1))
                })
            }
        }
    }
}