import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, Form, Input, Upload, Button, Icon, message } from "antd";
import { actionCreators } from "../store";
import "../../../assets/css/content.min.css";
import axios from "axios";
import Http from "../../../http";
const { TextArea } = Input;
class Uploading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileListV1: [],
      fileListV2: [],
      fileListV3: [],
      fileListI: [],
      loading: false
    };
  }
  handleSubmit = (e, eventKey, county) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const uploadvoice1 = values.uploadvoice1;
        const files = this.state.fileListI;
        const file1 = this.state.fileListV1;
        const file2 = this.state.fileListV2;
        const file3 = this.state.fileListV3;
        const data = new FormData();
        const data2 = new FormData();
        const data3 = new FormData();
        const data4 = new FormData();
        const data5 = new FormData();
        const songyangdata = new FormData();
        delete values.uploadvoice1;
        delete values.uploadvoice2;
        delete values.uploadvoice3;
        delete values.uploadimg;
        for (let i in values) {
          if (values[i] == undefined) {
            values[i] = "";
          }
          data.append(i, values[i]);
        }
        files.forEach(file => {
          data2.append("files", file);
        });
        data3.append("data", uploadvoice1);
        if (file1 !== "" || file1 !== undefined) {
          file1.forEach(file => {
            songyangdata.append("file", file);
          });
        }
        if (file2 !== "" || file2 !== undefined) {
          file2.forEach(file => {
            data4.append("file", file);
          });
        }
        if (file3 !== "" || file3 !== undefined) {
          file3.forEach(file => {
            data5.append("file", file);
          });
        }
        if (eventKey == "1_1") {
          this.setState({
            loading: true
          });
          axios.post(Http.apiHost + "/API/PlateNumber/add", data).then(res => {
            const id = res.data.data;
            data3.append("platenumberId", id);
            if (uploadvoice1 == "") {
              data3.set("data", "undefined");
            }
            axios
              .post(Http.apiHost + "/API/Voice/addMandarin", data3)
              .then(() => {
                message.success("添加成功");
                this.setState({
                  loading: false
                });
              });
            if (file2.length !== 0) {
              data4.append("platenumberId", id);
              axios
                .post(Http.apiHost + "/API/Audio/addEnglish", data4)
                .then(res => {});
            }
            if (file3.length !== 0) {
              data5.append("platenumberId", id);
              axios
                .post(Http.apiHost + "/API/Audio/addDialect", data5)
                .then(() => {});
            }
            if (files.length !== 0) {
              data2.append("platenumberId", id);
              axios
                .post(Http.apiHost + "/API/Picture/add", data2)
                .then(res => {});
            }
          });
        } else if (eventKey == "1_2") {
          this.setState({
            loading: true
          });
          axios.post(Http.apiHost + "/API/RoadNumber/add", data).then(res => {
            const id = res.data.data;
            data3.append("roadnumberId", id);
            if (uploadvoice1 == "") {
              data3.set("data", "undefined");
            }
            axios
              .post(Http.apiHost + "/API/Voice/addMandarin", data3)
              .then(() => {
                message.success("添加成功");
                this.setState({
                  loading: false
                });
              });
            if (file2.length !== 0) {
              data4.append("roadnumberId", id);
              axios
                .post(Http.apiHost + "/API/Audio/addEnglish", data4)
                .then(res => {});
            }
            if (file3.length !== 0) {
              data5.append("roadnumberId", id);
              axios
                .post(Http.apiHost + "/API/Audio/addDialect", data5)
                .then(() => {});
            }
            if (files.length !== 0) {
              data2.append("roadnumberId", id);
              axios
                .post(Http.apiHost + "/API/Picture/add", data2)
                .then(res => {});
            }
          });
        } else if (eventKey == "1_3") {
          this.setState({
            loading: true
          });
          axios
            .post(Http.apiHost + "/API/VillageNumber/add", data)
            .then(res => {
              const id = res.data.data;
              data3.append("villagenumberId", id);
              if (uploadvoice1 == "") {
                data3.set("data", "undefined");
              }
              axios
                .post(Http.apiHost + "/API/Voice/addMandarin", data3)
                .then(() => {
                  message.success("添加成功");
                  this.setState({
                    loading: false
                  });
                });
              if (file2.length !== 0) {
                data4.append("villagenumberId", id);
                axios
                  .post(Http.apiHost + "/API/Audio/addEnglish", data4)
                  .then(res => {});
              }
              if (files.length !== 0) {
                data2.append("villagenumberId", id);
                axios
                  .post(Http.apiHost + "/API/Picture/add", data2)
                  .then(res => {});
              }
            });
        } else if (eventKey == "1_4") {
          this.setState({
            loading: true
          });
          axios.post(Http.apiHost + "/API/Scenicspot/add", data).then(res => {
            const id = res.data.data;
            data3.append("scenicspotId", id);
            if (uploadvoice1 == "") {
              data3.set("data", "undefined");
            }
            axios
              .post(Http.apiHost + "/API/Voice/addMandarin", data3)
              .then(() => {
                message.success("添加成功");
                this.setState({
                  loading: false
                });
              });
            if (file2.length !== 0) {
              data4.append("scenicspotId", id);
              axios
                .post(Http.apiHost + "/API/Audio/addEnglish", data4)
                .then(res => {});
            }
            if (file3.length !== 0) {
              data5.append("scenicspotId", id);
              axios
                .post(Http.apiHost + "/API/Audio/addDialect", data5)
                .then(() => {});
            }
            if (files.length !== 0) {
              data2.append("scenicspotId", id);
              axios
                .post(Http.apiHost + "/API/Picture/add", data2)
                .then(res => {});
            }
          });
        } else if (eventKey == "1_5") {
          this.setState({
            loading: true
          });
          axios.post(Http.apiHost + "/API/Road/add", data).then(res => {
            const id = res.data.data;
            data3.append("roadId", id);
            if (uploadvoice1 == "") {
              data3.set("data", "undefined");
            }
            axios
              .post(Http.apiHost + "/API/Voice/addMandarin", data3)
              .then(() => {
                message.success("添加成功");
                this.setState({
                  loading: false
                });
              });
            if (file2.length !== 0) {
              data4.append("roadId", id);
              axios
                .post(Http.apiHost + "/API/Audio/addEnglish", data4)
                .then(res => {});
            }
            if (file3.length !== 0) {
              data5.append("roadId", id);
              axios
                .post(Http.apiHost + "/API/Audio/addDialect", data5)
                .then(() => {});
            }
            if (files.length !== 0) {
              data2.append("roadId", id);
              axios
                .post(Http.apiHost + "/API/Picture/add", data2)
                .then(res => {});
            }
          });
        }
      }
    });
  };
  render() {
    const { uploadvisible, eventKey, addandhide, county } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { fileListV1, fileListV2, fileListV3, fileListI } = this.state;
    const uploadvoice1 = {
      onRemove: file => {
        this.setState(state => {
          const index = state.fileListV1.indexOf(file);
          const newFileList = state.fileListV1.slice();
          newFileList.splice(index, 1);
          return {
            fileListV1: newFileList
          };
        });
      },
      beforeUpload: file => {
        this.setState(state => ({
          fileListV1: [...state.fileListV1, file]
        }));
        return false;
      },
      fileListV1
    };
    const uploadvoice2 = {
      onRemove: file => {
        this.setState(state => {
          const index = state.fileListV2.indexOf(file);
          const newFileList = state.fileListV2.slice();
          newFileList.splice(index, 1);
          return {
            fileListV2: newFileList
          };
        });
      },
      beforeUpload: file => {
        this.setState(state => ({
          fileListV2: [...state.fileListV2, file]
        }));
        return false;
      },
      fileListV2
    };
    const uploadvoice3 = {
      onRemove: file => {
        this.setState(state => {
          const index = state.fileListV3.indexOf(file);
          const newFileList = state.fileListV3.slice();
          newFileList.splice(index, 1);
          return {
            fileListV3: newFileList
          };
        });
      },
      beforeUpload: file => {
        this.setState(state => ({
          fileListV3: [...state.fileListV3, file]
        }));
        return false;
      },
      fileListV3
    };
    const uploadimg = {
      onRemove: file => {
        this.setState(state => {
          const index = state.fileListI.indexOf(file);
          const newFileList = state.fileListI.slice();
          newFileList.splice(index, 1);
          return {
            fileListI: newFileList
          };
        });
      },
      beforeUpload: file => {
        this.setState(state => ({
          fileListI: [...state.fileListI, file]
        }));
        return false;
      },
      fileListI
    };
    return (
      <Modal
        title="上传信息"
        className="uploadmodal"
        footer={null}
        visible={uploadvisible}
        onCancel={() => addandhide(eventKey)}
        destroyOnClose={true}
      >
        <Form
          className="upload"
          onSubmit={e => this.handleSubmit(e, eventKey, county)}
        >
          {eventKey == "1_2" ? (
            <Form.Item className="uploaditem" label="路牌编号">
              {getFieldDecorator("roadnum", {
                rules: [{ required: true, message: "请输入路牌编号" }]
              })(<Input className="uploadinput" />)}
            </Form.Item>
          ) : null}
          <Form.Item className="uploaditem" label="名称">
            {getFieldDecorator("name", {
              rules: [{ required: true, message: "请输入名称" }]
            })(<Input className="uploadinput" />)}
          </Form.Item>
          <Form.Item className="uploaditem" label="经度">
            {getFieldDecorator("lon", {})(<Input className="uploadinput" />)}
          </Form.Item>
          <Form.Item className="uploaditem" label="纬度">
            {getFieldDecorator("lat", {})(<Input className="uploadinput" />)}
          </Form.Item>
		  <Form.Item className="uploaditem" label="路线点">
		    {getFieldDecorator("roadline", {})(<Input className="uploadinput" placeholder="例子:[120.287989, 28.138526], [120.286905, 28.13934]" />)}
		  </Form.Item>
          <Form.Item className="uploaditem" label="类型">
            {getFieldDecorator("type", {})(<Input className="uploadinput" />)}
          </Form.Item>
          <Form.Item className="uploaditem" label="材质">
            {getFieldDecorator(
              "textofmater",
              {}
            )(<Input className="uploadinput" />)}
          </Form.Item>
          <Form.Item className="uploaditem" label="尺寸">
            {getFieldDecorator("size", {})(<Input className="uploadinput" />)}
          </Form.Item>
          <Form.Item className="uploaditem" label="设立年份">
            {getFieldDecorator(
              "yearofesent",
              {}
            )(<Input className="uploadinput" />)}
          </Form.Item>
          <Form.Item className="uploaditem" label="维护单位">
            {getFieldDecorator(
              "mainteunit",
              {}
            )(<Input className="uploadinput" />)}
          </Form.Item>
          <Form.Item className="uploaditem" label="维护次数">
            {getFieldDecorator(
              "numofmaint",
              {}
            )(<Input className="uploadinput" />)}
          </Form.Item>
          <Form.Item className="uploaditem" label="维护期限">
            {getFieldDecorator(
              "maintperiod",
              {}
            )(<Input className="uploadinput" />)}
          </Form.Item>
          <Form.Item className="uploaditem" label="维护费用">
            {getFieldDecorator(
              "maintcost",
              {}
            )(<Input className="uploadinput" />)}
          </Form.Item>
          <Form.Item className="uploaditem" label="维护台账">
            {getFieldDecorator(
              "maintaccounts",
              {}
            )(<Input className="uploadinput" />)}
          </Form.Item>
          <Form.Item className="uploaditem" label="语音(中文)">
            {getFieldDecorator(
              "uploadvoice1",
              {}
            )(
              <TextArea
                autosize={{ minRows: 2, maxRows: 6 }}
                className="uploadinput"
              />
            )}
          </Form.Item>
          <Form.Item className="uploaditem" label="英文内容">
            {getFieldDecorator(
              "englishword",
              {}
            )(
              <TextArea
                autosize={{ minRows: 2, maxRows: 6 }}
                className="uploadinput"
              />
            )}
          </Form.Item>
          <Form.Item className="uploaditem" label="语音(英文)">
            {getFieldDecorator(
              "uploadvoice2",
              {}
            )(
              <Upload {...uploadvoice2} listType="text">
                {fileListV2.length < 1 ? (
                  <Button>
                    <Icon type="upload" /> 上传
                  </Button>
                ) : null}
              </Upload>
            )}
          </Form.Item>
          {eventKey == "1_3" ? null : (
            <Form.Item className="uploaditem" label="语音(方言)">
              {getFieldDecorator(
                "uploadvoice3",
                {}
              )(
                <Upload {...uploadvoice3} listType="text">
                  {fileListV3.length < 1 ? (
                    <Button>
                      <Icon type="upload" /> 上传
                    </Button>
                  ) : null}
                </Upload>
              )}
            </Form.Item>
          )}
          <Form.Item className="uploaditem" label="图片">
            {getFieldDecorator(
              "uploadimg",
              {}
            )(
              <Upload {...uploadimg} listType="text">
                {fileListI.length < 3 ? (
                  <Button>
                    <Icon type="upload" /> 上传
                  </Button>
                ) : null}
              </Upload>
            )}
          </Form.Item>
          <Form.Item style={{ textAlign: "center" }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={this.state.loading}
            >
              上传
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}
const mapState = state => ({
  uploadvisible: state.getIn(["BasicManagement", "uploadvisible"]),
  eventKey: state.getIn(["navigationBar", "eventKey"]),
  county: state.getIn(["navigationBar", "county"])
});
const mapDispatch = dispatch => ({
  addandhide(key) {
    dispatch(actionCreators.addandhide(key));
  }
});

const WrappedNormalLoginForm = Form.create()(Uploading);
export default connect(mapState, mapDispatch)(WrappedNormalLoginForm);
