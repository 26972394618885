import * as constants from "./constants";
import axios from "axios";
import Http from "../../../http";
const keys = {
  "6_1": "Ocplacenames",
  "6_2": "Avillagerhyme",
  "6_3": "Chafood",
  "6_4": "Focustoms",
  "6_5": "Famousscenery",
  "6_6": "Nolegaculture",
  // "6_7": "Placenacultu",
  "6_8": "Streetplana",
  "6_9": "Admindivsion"
};
//上传页面显示
export const uploadingshow = () => ({
  type: constants.UPLOADING_SHOW
});
//上传页面隐藏
export const uploadinghide = () => ({
  type: constants.UPLOADING_HIDE
});
//编辑页面显示
export const compileshow = () => ({
  type: constants.COMPILE_SHOW
});
//编辑页面隐藏
export const compilehide = () => ({
  type: constants.COMPILE_HIDE
});
//获取表格内容的总数
const setcount = count => ({
  type: constants.SET_COUNT,
  count
});
//获取表格内容
const setlist = list => ({
  type: constants.SET_LIST,
  list
});
//重置current
const refreshCurrent = () => ({
  type: constants.REFRESH_CURRENT
});
//搜索框内容
const values = value => ({
  type: constants.VALUES,
  values: value
});
//设置current
const currents = index => ({
  type: constants.CURRENTS,
  current: index
});
//设置content
const content = record => ({
  type: constants.CONTENT,
  content: record
});

//公共请求
export const request = (key, page, type, name, id) => {
  return dispatch => {
    let url =
      "/API/" + key + "/getList1?page=" + page + "&size=10&name=" + name;
    if (type.length) {
      url += "&type=" + type;
    }
    if (id) {
      axios
        .post(Http.apiHost + "/API/" + key + "/del?id=" + id)
        .then(res => {});
    }
    axios.get(Http.apiHost + url).then(res => {
      const result = res.data.data;
      dispatch(setcount(result.count));
      dispatch(setlist(result.list));
    });
  };
};

//请求表格内容
export const contentManagementload = id => {
  return dispatch => {
    const tabskey = sessionStorage.getItem("tabskey");
    if (id == "6_7") {
      dispatch(request("Placenacultu", 1, tabskey, "", ""));
    } else {
      dispatch(request(keys[id], 1, "", "", ""));
    }
    dispatch(refreshCurrent());
  };
};
//上传成功后重新请求表格内容
export const anewRequest = key => {
  return dispatch => {
    const tabskey = sessionStorage.getItem("tabskey");
    if (key == "6_7") {
      dispatch(request("Placenacultu", 1, tabskey, "", ""));
    } else {
      dispatch(request(keys[key], 1, "", "", ""));
    }

    dispatch(uploadinghide());
    dispatch(refreshCurrent());
  };
};
//查询
export const inputsearch = (value, key) => {
  return dispatch => {
    const tabskey = sessionStorage.getItem("tabskey");
    if (key == "6_7") {
      dispatch(request("Placenacultu", 1, tabskey, value, ""));
    } else {
      dispatch(request(keys[key], 1, "", value, ""));
    }

    dispatch(values(value));
    dispatch(refreshCurrent());
  };
};
//当搜索框内容为空
export const searchchange = (value, key) => {
  return dispatch => {
    const tabskey = sessionStorage.getItem("tabskey");
    if (value == "") {
      if (key == "6_7") {
        dispatch(request("Placenacultu", 1, tabskey, "", ""));
      } else {
        dispatch(request(keys[key], 1, "", "", ""));
      }
    }
    dispatch(values());
    dispatch(refreshCurrent());
  };
};
//分页点击事件
export const paginationchange = (index, key, values) => {
  return dispatch => {
    const tabskey = sessionStorage.getItem("tabskey");
    if (values == undefined || values == "") {
      if (key == "6_7") {
        dispatch(request("Placenacultu", index, tabskey, "", ""));
      } else {
        dispatch(request(keys[key], index, "", "", ""));
      }
    } else {
      if (key == "6_7") {
        dispatch(request("Placenacultu", index, tabskey, values, ""));
      } else {
        dispatch(request(keys[key], index, "", values, ""));
      }
    }
    dispatch(currents(index));
  };
};
//删除
export const removepupopok = (value, key, current) => {
  return dispatch => {
    const tabskey = sessionStorage.getItem("tabskey");
    if (key == "6_7") {
      dispatch(request("Placenacultu", current, tabskey, "", value.id));
    } else {
      dispatch(request(keys[key], current, "", "", value.id));
    }

    dispatch(currents(current));
  };
};
//打开修改框 并获取当前点击行的内容
export const compile = record => {
  return dispatch => {
    dispatch(compileshow());
    dispatch(content(record));
  };
};
//上传成功后 关闭修改框，同时重新请求表格内容
export const compilesuccess = (key, index) => {
  return dispatch => {
    const tabskey = sessionStorage.getItem("tabskey");
    if (key == "6_7") {
      dispatch(request("Placenacultu", index, tabskey, "", ""));
    } else {
      dispatch(request(keys[key], index, "", "", ""));
    }

    dispatch(compilehide());
  };
};
export const tabsonchange = e => {
  return dispatch => {
    sessionStorage.setItem("tabskey", e);
    dispatch(request("Placenacultu", 1, e, "", ""));
    dispatch(refreshCurrent());
  };
};
