import React, { Component } from "react";
import { Form, Input, Button, message, Icon } from "antd";
import Footer from './Footer'
import "../../assets/css/content.min.css";
import axios from "axios";
import Http from "../../http";
import qtbackground from "../../assets/imgs/beijing.png";
class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const data = new FormData();
        for (let i in values) {
          data.append(i, values[i]);
        }
        axios.defaults.withCredentials = true; console.log(Http.apiHost)
        axios.post(Http.apiHost + "/API/User/login", data).then(res => {
          const result = res.data.data;
          const messages = res.data.message;
          if (result == null) {
            message.error(messages);
          } else {
            message.success("登录成功");
            sessionStorage.setItem("name", result.userName);
            sessionStorage.setItem("level", result.level);
            sessionStorage.setItem("load", "1");
            sessionStorage.setItem("county", result.county);
            this.props.history.push({ pathname: "/BasicManagement/1" });
            window.location.reload()
          }
        });
      }
    });
  };

  componentDidMount() {
    this.props.form.setFieldsValue({ county: "青田" })
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div
        className="register"
      // style={{ background: `url(${qtbackground}) no-repeat` }}
      >
        <Form className="registerform" onSubmit={e => this.handleSubmit(e)}>
          <Form.Item>
            <div className="registerdiv">青田智慧地名应用平台</div>
          </Form.Item>
          <Form.Item className="registeritem">
            {getFieldDecorator("account", {
              rules: [{ required: true, message: "请输入账号!" }]
            })(
              <Input
                prefix={
                  <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                placeholder="账号"
                className="registerinput"
              />
            )}
          </Form.Item>
          <Form.Item className="registeritem">
            {getFieldDecorator("password", {
              rules: [{ required: true, message: "请输入密码!" }]
            })(
              <Input
                prefix={
                  <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                type="password"
                placeholder="密码"
                className="registerinput"
              />
            )}
          </Form.Item>
          <Form.Item className="registeritem" >
            {getFieldDecorator("county", {

            })(
              <Input
                placeholder="政区"
                className="registerinput"
                setfieldsvalue={this.state.county}
                readOnly="true "
              />
            )}
          </Form.Item>
          <Form.Item style={{ textAlign: "center", marginTop: "20px" }}>
            <Button type="primary" htmlType="submit">
              登录
            </Button>
          </Form.Item>
        </Form>
        <Footer></Footer>
      </div>
    );
  }
}
export default Form.create()(Register);
