import React, { Component } from "react";
import { connect } from "react-redux";
import BraftEditor from "braft-editor";
import { Modal, Form, Input, Upload, Button, Icon, message } from "antd";
import { actionCreators } from "../store";
import Http from "../../../http";
import axios from "axios";

const { TextArea } = Input;
const keys = {
  "6_1": "Ocplacenames",
  "6_2": "Avillagerhyme",
  "6_3": "Chafood",
  "6_4": "Focustoms",
  "6_5": "Famousscenery",
  "6_6": "Nolegaculture",
  // "6_7": "",
  "6_8": "Streetplana"
};
class Uploading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileListI: [],
      loading: false,
      editorState: BraftEditor.createEditorState(null)
    };
  }
  uploadContent = (key, data, data2) => {
    const { eventKey } = this.props;
    axios.post(Http.apiHost + "/API/" + key + "/add", data).then(res => {
      const id = res.data.data.id;
      data2.append("id", id);
      axios.post(Http.apiHost + "/API/" + key + "/addTxt", data2).then(res => {
        message.success("添加成功");
        this.props.anewRequest(eventKey);
        this.setState({
          loading: false
        });
      });
    });
  };
  uploadVideo = (key, data) => {
    const { eventKey } = this.props;
    axios.post(Http.apiHost + "/API/" + key + "/add", data).then(res => {
      message.success("添加成功");
      this.props.anewRequest(eventKey);
      this.setState({
        loading: false
      });
    });
  };
  uploadadministration = data => {
    const { eventKey } = this.props;
    axios.post(Http.apiHost + "/API/Admindivsion/add", data).then(res => {
      message.success("添加成功");
      this.props.anewRequest(eventKey);
      this.setState({
        loading: false
      });
    });
  };
  handleSubmit = (e, eventKey) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        delete values.text;
        delete values.uploadimg;
        const text = this.state.editorState.toHTML();
        const file = this.state.fileListI;
        const data = new FormData();
        const data2 = new FormData();
        for (let i in values) {
          data.append(i, values[i]);
        }
        if (file !== "") {
          file.forEach(file => {
            data.append("file", file);
          });
        }
        data2.append("txt", text);
        this.setState({
          loading: true
        });
        const tabskey = sessionStorage.getItem("tabskey");
        if (eventKey == "6_7") {
          data.append("type", tabskey);
          this.uploadContent("Placenacultu", data, data2);
        } else if (eventKey == "6_9") {
          data.append("text", text);
          this.uploadadministration(data);
        } else {
          this.uploadContent(keys[eventKey], data, data2);
        }
      }
    });
  };
  handleEditorChange = editorState => {
    this.setState({
      editorState
    });
  };
  render() {
    const {
      uploadvisible,
      uploadinghide,
      eventKey,
      county,
      names
    } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { fileListI, editorState } = this.state;
    const uploadimg = {
      onRemove: file => {
        this.setState(state => {
          const index = state.fileListI.indexOf(file);
          const newFileList = state.fileListI.slice();
          newFileList.splice(index, 1);
          return {
            fileListI: newFileList
          };
        });
      },
      beforeUpload: file => {
        this.setState(state => ({
          fileListI: [...state.fileListI, file]
        }));
        return false;
      },
      fileListI
    };
    const controls = [
      "undo",
      "redo",
      "separator",
      "font-size",
      "line-height",
      "letter-spacing",
      "headings",
      "separator",
      "text-color",
      "bold",
      "italic",
      "underline",
      "strike-through",
      "separator",
      "superscript",
      "subscript",
      "remove-styles",
      "separator",
      "text-indent",
      "text-align",
      "separator",
      "list-ul",
      "list-ol",
      "separator",
      "link",
      "separator",
      "hr",
      "media",
      "clear"
    ];
    //指定uploadFn
    const myUploadFn = param => {
      const serverURL = Http.apiHost + "/API/Ocplacenames/addTxtFile";
      const xhr = new XMLHttpRequest();
      const fd = new FormData();

      const successFn = response => {
        // 假设服务端直接返回文件上传后的地址
        // 上传成功后调用param.success并传入上传后的文件地址
        param.success({
          url: Http.apiHost + "/API" + xhr.responseText
        });
      };

      const progressFn = event => {
        // 上传进度发生变化时调用param.progress
        param.progress((event.loaded / event.total) * 100);
      };

      const errorFn = response => {
        // 上传发生错误时调用param.error
        param.error({
          msg: "unable to upload."
        });
      };

      xhr.upload.addEventListener("progress", progressFn, false);
      xhr.addEventListener("load", successFn, false);
      xhr.addEventListener("error", errorFn, false);
      xhr.addEventListener("abort", errorFn, false);

      fd.append("file", param.file);
      xhr.open("POST", serverURL, true);
      xhr.send(fd);
    };
    return (
      <Modal
        title="上传文章"
        width="900px"
        className="uploadmodal"
        visible={uploadvisible}
        footer={null}
        destroyOnClose={true}
        onCancel={() => uploadinghide()}
      >
        <Form className="upload" onSubmit={e => this.handleSubmit(e, eventKey)}>
          <Form.Item className="uploaditem" label="标题">
            {getFieldDecorator("name", {
              rules: [{ required: true, message: "请输入名称" }]
            })(<Input className="uploadinput" />)}
          </Form.Item>
          {names == "行政区划" ? null : (
            <Form.Item className="uploaditem" label="图片">
              {getFieldDecorator(
                "uploadimg",
                {}
              )(
                <Upload {...uploadimg} listType="text">
                  {fileListI.length < 1 ? (
                    <Button>
                      <Icon type="upload" /> 上传
                    </Button>
                  ) : null}
                </Upload>
              )}
            </Form.Item>
          )}

          {names == "行政区划" ? (
            <Form.Item className="uploaditem" label="经纬度">
              {getFieldDecorator(
                "lonAndLat",
                {}
              )(
                <Input
                  className="uploadinput"
                  placeholder="请输入高德经纬度(经度在前)"
                />
              )}
            </Form.Item>
          ) : null}
          <Form.Item className="uploaditem" label="文本内容">
            {getFieldDecorator(
              "text",
              {}
            )(
              <div style={{ width: "760px", border: "1px solid #d8d8d8" }}>
                <BraftEditor
                  value={editorState}
                  contentStyle={{ width: "100%", height: "500px" }}
                  controls={controls}
                  onChange={this.handleEditorChange}
                  media={{ uploadFn: myUploadFn }}
                />
              </div>
            )}
          </Form.Item>
          <Form.Item style={{ textAlign: "center", marginTop: "30px" }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={this.state.loading}
            >
              上传
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}
const mapState = state => ({
  eventKey: state.getIn(["navigationBar", "eventKey"]),
  county: state.getIn(["navigationBar", "county"]),
  names: state.getIn(["navigationBar", "names"]),
  uploadvisible: state.getIn(["contentManagement", "uploadvisible"])
});
const mapDispatch = dispatch => ({
  uploadinghide() {
    dispatch(actionCreators.uploadinghide());
  },
  anewRequest(key) {
    dispatch(actionCreators.anewRequest(key));
  }
});
const WrappedNormalLoginForm = Form.create()(Uploading);
export default connect(mapState, mapDispatch)(WrappedNormalLoginForm);
