import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Button, Input, Table } from 'antd'
import { actionCreators } from './store'
import '../../assets/css/content.min.css'
import Photo from './compoents/photo'
import Statistics from './compoents/statistics'
const { Search } = Input;
class Maintain extends Component {
    componentDidMount() {
        this.props.getmaintain()
    }
    render() {
        const { names, maintainlist, maintaintotal, paginationchange, current, inputsearch, searchchange, value, photoshow, photovisible, statisticsshow,
            statisticsvisible
        } = this.props
        const columns = [
            {
                title: '维护类型',
                dataIndex: 'maintType',
            },
            {
                title: '编号',
                dataIndex: 'number',
            },
            {
                title: '维护内容',
                dataIndex: 'maintContent',
            },
            {
                title: '维护公司',
                dataIndex: 'maintCompany',
            },
            {
                title: '联系人',
                dataIndex: 'contacts',
            },
            {
                title: '联系电话',
                dataIndex: 'contactsNum',
            },
            {
                title: '费用',
                dataIndex: 'cost',
            },
            {
                title: '维修时间',
                dataIndex: 'maintTime',
            },
            {
                title: '图片',
                dataIndex: 'pictureUrl',
                render: (text, record) => {
                    return (
                        <Button className='minbutton' type="link" onClick={() => photoshow(record.pictureUrl)} >{record.pictureUrl.length}张</Button>
                    )
                }
            },
        ]
        const pagination = {
            current: current,
            total: maintaintotal,
            defaultPageSize: 10,
            onChange: (current) => paginationchange(current, value)
        }
        return (
            <div>
                <div className='gltitle'>
                    <div className='titleblue' />
                    <div className='titlename'>
                        {names}
                    </div>
                </div>
                <div className='glbutton'>
                    <Search
                        className='search'
                        placeholder="请输入要查询维护类型"
                        style={{ width: 240 }}
                        allowClear
                        onSearch={(value) => inputsearch(value)}
                        onChange={(e) => searchchange(e)}
                    />
                    <Button type='primary' onClick={() => statisticsshow()}>统计</Button>
                </div>
                <Table rowKey='id' className='gltable' columns={columns} dataSource={maintainlist} pagination={pagination} />
                {
                    photovisible ?
                        <Photo /> : null
                }
                {
                    statisticsvisible ?
                        <Statistics /> : null
                }
            </div>
        );
    }
}
const mapState = (state) => ({
    names: state.getIn(['navigationBar', 'names']),
    maintainlist: state.getIn(['maintain', 'maintainlist']),
    maintaintotal: state.getIn(['maintain', 'maintaintotal']),
    current: state.getIn(['maintain', 'current']),
    value: state.getIn(['maintain', 'value']),
    photovisible: state.getIn(['maintain', 'photovisible']),
    statisticsvisible: state.getIn(['maintain', 'statisticsvisible']),
})
const mapDispatch = (dispatch) => ({
    //获取内容
    getmaintain() {
        dispatch(actionCreators.getmaintain())
    },
    //点击分页获取内容
    paginationchange(current, value) {
        dispatch(actionCreators.paginationchange(current, value))
    },
    //搜索回车或点击搜索按钮时获取内容
    inputsearch(value) {
        dispatch(actionCreators.inputsearch(value))
    },
    //当搜索框内容为空时重新获取内容
    searchchange(e) {
        dispatch(actionCreators.searchchange(e.target.value))
    },
    photoshow(item) {
        dispatch(actionCreators.photoshow(item))
    },
    statisticsshow() {
        dispatch(actionCreators.statisticsshow())
    }
})

export default connect(mapState, mapDispatch)(Maintain);