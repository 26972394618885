import * as constants from './constants'
import { fromJS } from 'immutable'
const defaultState = fromJS({
    content:[]
})
export default (state = defaultState, action) => {
    switch (action.type) {
        case constants.SET_MAP_CONTENT:
            return state.set('content',action.content)
        default:
            return state
    }

}