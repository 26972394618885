import * as constants from './constants'
import axios from 'axios';
import Http from '../../../http'
export const setmapcontent = (result) =>({
    type:constants.SET_MAP_CONTENT,
    content:result
})

export const getmapcontent= () =>{
    return (dispatch)=>{
        axios.get(Http.apiHost + '/API/Count/getAllCount').then((res)=>{
            const result = res.data.data
            dispatch(setmapcontent(result))
        })
    }
}