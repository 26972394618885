import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Modal, Form, Input, Upload, Button, Icon, message } from 'antd';
import { actionCreators } from '../store'
import '../../../assets/css/content.min.css'
import axios from 'axios';
import Http from '../../../http'
class Maintain extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            fileListI: [],
        }
    }
    handleSubmit = (e, eventKey, current, content) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                delete values.uploadimg
                const files = this.state.fileListI
                const id = content.id
                values.id = id
                const data = new FormData()
                const data2 = new FormData()
                for (let i in values) {
                    data.append(i, values[i])
                }
                files.forEach(file => {
                    data2.append('files', file)
                })
                this.setState({
                    loading: true
                })
                const key = {
                    '1_1': "/PlateNumber/addMaintOfPlateNum",
                    '1_2': "/RoadNumber/addMaintrecordsOfRoadNum",
                    '1_3': "/VillageNumber/addMaintOfVillage",
                    '1_4': "/Scenicspot/addMaintrecordsOfScen",
                    '1_5': "/Road/addMaintOfRoad",
                }
                axios.post(Http.apiHost + '/API' + key[eventKey], data).then((res) => {
                    const maintrecordsId = res.data.data
                    data2.append('maintrecordsId', maintrecordsId)
                    axios.post(Http.apiHost + '/API/Picture/add', data2).then((res) => {
                        message.success('上传成功');
                        this.props.maintainsuccess(eventKey, current)
                        this.setState({
                            loading: false
                        })
                    })
                }).error((res) => {
                    message.success('上传失败')
                })
            }
        });
    }
    render() {
        const { maintainvisible, maintainhide, eventKey, current, content } = this.props
        const { getFieldDecorator } = this.props.form;
        const { fileListI } = this.state
        const uploadimg = {
            onRemove: file => {
                this.setState(state => {
                    const index = state.fileListI.indexOf(file);
                    const newFileList = state.fileListI.slice();
                    newFileList.splice(index, 1);
                    return {
                        fileListI: newFileList,
                    };
                });
            },
            beforeUpload: file => {
                this.setState(state => ({
                    fileListI: [...state.fileListI, file],
                }));
                return false;
            },
            fileListI
        }
        return (
            <Modal title='上传维护信息' className='maintainmodal' footer={null} visible={maintainvisible} onCancel={() => maintainhide()} >
                <Form className='maintain' onSubmit={(e) => this.handleSubmit(e, eventKey, current, content)}>
                    <Form.Item className='maintainitem' label='维护内容'>
                        {getFieldDecorator('maintcontent', {
                            rules: [{ required: true, message: '请输入维护内容' }],
                        })(<Input
                            className='maintaininput'
                        />)}
                    </Form.Item>
                    <Form.Item className='maintainitem' label='维护公司'>
                        {getFieldDecorator('maintcompany', {
                            rules: [{ required: true, message: '请输入维护公司' }],
                        })(<Input
                            className='maintaininput'
                        />)}
                    </Form.Item>
                    <Form.Item className='maintainitem' label='联系人'>
                        {getFieldDecorator('contacts', {
                            rules: [{ required: true, message: '请输入联系人' }],
                        })(<Input
                            className='maintaininput'
                        />)}
                    </Form.Item>
                    <Form.Item className='maintainitem' label='联系电话'>
                        {getFieldDecorator('contactsnum', {
                            rules: [{ required: true, message: '请输入联系电话' }],
                        })(<Input
                            className='maintaininput'
                        />)}
                    </Form.Item>
                    <Form.Item className='maintainitem' label='费用'>
                        {getFieldDecorator('cost', {
                            rules: [{ required: true, message: '请输入费用' }],
                        })(<Input
                            className='maintaininput'
                        />)}
                    </Form.Item>
                    <Form.Item className='maintainitem' label='图片'>
                        {getFieldDecorator('uploadimg', {
                            rules: [{ required: true, message: '请选择图片文件' }]
                        })(
                            <Upload {...uploadimg} listType="text">
                                {
                                    fileListI.length < 3 ?
                                        <Button>
                                            <Icon type="upload" /> 上传
                                        </Button> : null
                                }

                            </Upload>
                        )}
                    </Form.Item>
                    <Form.Item style={{ textAlign: 'center' }}>
                        <Button type="primary" htmlType="submit" loading={this.state.loading}>上传</Button>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}
const mapState = (state) => ({
    eventKey: state.getIn(['navigationBar', 'eventKey']),
    maintainvisible: state.getIn(['BasicManagement', 'maintainvisible']),
    current: state.getIn(['BasicManagement', 'current']),
    content: state.getIn(['BasicManagement', 'content']),
})
const mapDispatch = (dispatch) => ({
    maintainhide() {
        dispatch(actionCreators.maintainhide())
    },
    maintainsuccess(key, current) {
        dispatch(actionCreators.maintainsuccess(key, current))
    }
})
const WrappedNormalLoginForm = Form.create()(Maintain)
export default connect(mapState, mapDispatch)(WrappedNormalLoginForm);