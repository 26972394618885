import React, { Component } from 'react';
import { Modal, DatePicker, Button, message } from 'antd';
import { connect } from 'react-redux'
import { actionCreators } from '../store'
import ExportJsonExcel from 'js-export-excel'
import '../../../assets/css/content.min.css'
class Statistics extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isopen: false,
            time: null
        }
    }
    //面板打开/关闭时的回调
    handleOpenChange = (status) => {
        if (status) {
            this.setState({ isopen: true })
        } else {
            this.setState({ isopen: false })
        }
    }
    //时间转换
    formatDate = (time) => {
        if (!time) {
            return '';
        }
        let date = new Date(time);//毫秒转换成日期
        let year = date.getFullYear();
        return year;//格式控制
    }
    //选中年份后获取总价格
    onPanelChange = (val) => {
        const year = this.formatDate(val)
        this.setState({
            time: val,
            isopen: false
        })
        this.props.getcontent(year)
    }
    //清除
    clearValue = () => {
        this.setState({
            time: null
        })
    }
    //导出生成excel
    downloadExcel(val, time) {
        const year = this.formatDate(time)
        if (year == '') {
            message.error('请选择年份')
        }
        else {
            var option = {};
            let dataTable = [];
            if (val) {
                for (let i in val) {
                    if (val) {
                        let obj = {
                            '维护类型': val[i].mainttype,
                            '维护内容': val[i].maintcontent,
                            '维护公司': val[i].maintcompany,
                            '费用': val[i].cost,
                            '时间': val[i].mainttime
                        }
                        dataTable.push(obj);
                    }
                }
            }
            option.fileName = year + '年维护费用'
            option.datas = [
                {
                    sheetData: dataTable,
                    sheetName: 'sheet',
                    sheetFilter: ['维护类型', '维护内容', '维护公司', '费用', '时间'],
                    sheetHeader: ['维护类型', '维护内容', '维护公司', '费用', '时间'],
                }
            ];
            var toExcel = new ExportJsonExcel(option);
            toExcel.saveExcel();
        }
    }
    render() {
        const { statisticsvisible, statisticsremove, statisticscount, statisticslist } = this.props
        const { time } = this.state
        return (
            <Modal title='统计' className='statistics' width='600px' footer={null} visible={statisticsvisible} onCancel={() => statisticsremove()}>
                <div className='statisticschild'>
                    时间：
                    <DatePicker mode="year"
                        value={time}
                        format="YYYY"
                        placeholder='请选择年份'
                        onPanelChange={this.onPanelChange}
                        onOpenChange={this.handleOpenChange}
                        open={this.state.isopen}
                        onChange={this.clearValue}
                    />
                </div>
                <div className='statisticschild'>
                    总计：{statisticscount} {
                        statisticscount ? '元' : null
                    }
                </div>
                <Button className='statisticschild' type='primary' onClick={() => this.downloadExcel(statisticslist, time)}>导出数据</Button>
            </Modal>
        );
    }
}
const mapState = (state) => ({
    statisticsvisible: state.getIn(['maintain', 'statisticsvisible']),
    statisticscount: state.getIn(['maintain', 'statisticscount']),
    statisticslist: state.getIn(['maintain', 'statisticslist']),
})
const mapDispatch = (dispatch) => ({
    statisticsremove() {
        dispatch(actionCreators.statisticsremove())
    },
    getcontent(year) {
        dispatch(actionCreators.getcontent(year))
    }
})
export default connect(mapState, mapDispatch)(Statistics);