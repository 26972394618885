import React, { Component } from 'react';
import { Modal, Carousel, Icon } from 'antd';
import { connect } from 'react-redux'
import { actionCreators } from '../store'
import Http from "../../../http";
import '../../../assets/css/content.min.css'
class Photo extends Component {
    handlePrev = () => {
        this.refs.img.prev(); //ref = img
    }
    handleNext = () => {
        this.refs.img.next();
    }
    render() {
        const { photovisible, photohide, photourl } = this.props
        return (
            <Modal title='图片' className='carouselmadal' width='600px' footer={null} visible={photovisible} onCancel={() => photohide()}>
                <Icon type="left" className='icon' onClick={this.handlePrev} />
                <Carousel className='carousel' ref='img'>
                    {
                        photourl.map((item, index) => {
                            return (
                                <div key={index} >
                                    <img src={Http.apiHost + '/API/' + item} alt="" />
                                </div>

                            )
                        })
                    }
                </Carousel>
                <Icon type="right" className='icon' onClick={this.handleNext} />
            </Modal>
        );
    }
}
const mapState = (state) => ({
    photovisible: state.getIn(['maintain', 'photovisible']),
    photourl: state.getIn(['maintain', 'photourl'])
})
const mapDispatch = (dispatch) => ({
    photohide() {
        dispatch(actionCreators.photohide())
    }
})
export default connect(mapState, mapDispatch)(Photo);