import * as constants from "./constants";
import { fromJS } from "immutable";
const defaultState = fromJS({
  visible: false,
  sourcevisible: false,
  uploadvisible: false,
  carouselvisible: false,
  amendvisible: false,
  maintainvisible: false,
  content: [],
  current: 1
});
export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.LOADLISTS:
      return state.set("list", action.list);
    case constants.LOADTOTALS:
      return state.set("total", action.total);
    case constants.SOURCESHOW:
      return state.set("sourcevisible", action.sourcevisible);
    case constants.SOURCEHIDE:
      return state.set("sourcevisible", action.sourcevisible);
    case constants.ONVISIBLE:
      return state.set("visible", action.visible);
    case constants.QRCODEVISIBLE:
      return state.set("visible", action.visible);
    case constants.QRCODECONTENT:
      return state.set("content", action.content);
    case constants.REMOVEPUPOPOK:
      return state.set("show", action.show);
    case constants.UPLOADING_SHOW:
      return state.set("uploadvisible", action.uploadvisible);
    case constants.UPLOADING_HIDE:
      return state.set("uploadvisible", action.uploadvisible);
    case constants.CAROUSEL_SHOW:
      return state.set("carouselvisible", action.carouselvisible);
    case constants.CAROUSEL_HIDE:
      return state.set("carouselvisible", action.carouselvisible);
    case constants.CURRENTS:
      return state.set("current", action.current);
    case constants.VALUES:
      return state.set("values", action.values);
    case constants.INITCURRENTS:
      return state.set("current", action.current);
    case constants.AMEND_SHOW:
      return state.set("amendvisible", action.amendvisible);
    case constants.AMEND_HIDE:
      return state.set("amendvisible", action.amendvisible);
    case constants.MAINTAIN_SHOW:
      return state.set("maintainvisible", action.maintainvisible);
    case constants.MAINTAIN_HIDE:
      return state.set("maintainvisible", action.maintainvisible);
    default:
      return state;
  }
};
