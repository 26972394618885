export default class Http {
    static get apiHost() {
        return this._apiHost;
    }

    static set apiHost(value) {
        this._apiHost = value;
    }

    static _apiHost = "";
    static http() {
        const root = document.getElementById("root");
        // this.apiHost = root.getAttribute("data-api-host")
		// this.apiHost = 'http://123.56.174.57:1240'
        this.apiHost = window.location.protocol+"//"+window.location.host;
        console.log('11')
    }
}
