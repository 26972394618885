import * as constants from './constants'
import { fromJS } from 'immutable'
const defaultState = fromJS({
    current: 1
})

export default (state = defaultState, action) => {
    switch (action.type) {
        case constants.SET_LIST:
            return state.set('list', action.list)
        case constants.SET_TOTAL:
            return state.set('total', action.total)
        case constants.SET_CURRENT:
            return state.set('current', action.current)
        case constants.SET_INPUT_VALUE:
            return state.set('value', action.value)
        default:
            return state
    }

}