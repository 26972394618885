import React, { Component } from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import NavigationBar from "./compoents/navigationBar";
import BasicManagement from "./compoents/BasicManagement";
import Maintain from "./compoents/maintain";
import MassivePoint from "./compoents/MassivePoint";
import Statistics1 from "./compoents/statistics1";
import Statistics2 from "./compoents/statistics2";
import ChangePassword from "./compoents/ChangePassword";
import FeedbackSet from "./compoents/feedbackSet";
import FeedbackList from "./compoents/feedbackList";
import Register from "./compoents/register";
import ContentManagement from "./compoents/contentManagement";
import store from "./store";
import Http from "./http";
import axios from "axios";
import "braft-editor/dist/index.css";
Http.http();
class App extends Component {
  componentWillMount() {
    axios.defaults.withCredentials = true;
  }
  render() {
    const load = sessionStorage.getItem("load");
    return (
      <Provider store={store}>
        <Router>
          {load == "1" ? (
            <Switch>
              <NavigationBar>
                <Route exact path="/" component={BasicManagement} />
                <Route path="/BasicManagement/1" component={BasicManagement} />
                <Route path="/BasicManagement/2" component={BasicManagement} />
                <Route path="/BasicManagement/3" component={BasicManagement} />
                <Route path="/BasicManagement/4" component={BasicManagement} />
                <Route path="/BasicManagement/5" component={BasicManagement} />
                <Route path="/Maintain" component={Maintain} />
				<Route path="/ChangePassword" component={ChangePassword} />
                <Route path="/MassivePoint" component={MassivePoint} />
                <Route path="/Statistics1/1" component={Statistics1} />
                <Route path="/Statistics1/2" component={Statistics1} />
                <Route path="/Statistics2/1" component={Statistics2} />
                <Route path="/Statistics2/2" component={Statistics2} />
                <Route path="/FeedbackSet" component={FeedbackSet} />
                <Route path="/FeedbackList" component={FeedbackList} />
                <Route
                  path="/ContentManagement/1"
                  component={ContentManagement}
                />
                <Route
                  path="/ContentManagement/2"
                  component={ContentManagement}
                />
                <Route
                  path="/ContentManagement/3"
                  component={ContentManagement}
                />
                <Route
                  path="/ContentManagement/4"
                  component={ContentManagement}
                />
                <Route
                  path="/ContentManagement/5"
                  component={ContentManagement}
                />
                <Route
                  path="/ContentManagement/6"
                  component={ContentManagement}
                />
                <Route
                  path="/ContentManagement/7"
                  component={ContentManagement}
                />
                <Route
                  path="/ContentManagement/8"
                  component={ContentManagement}
                />
                <Route
                  path="/ContentManagement/9"
                  component={ContentManagement}
                />
              </NavigationBar>
            </Switch>
          ) : (
            <Switch>
              <Route path="*" component={Register} />
            </Switch>
          )}
        </Router>
      </Provider>
    );
  }
}

export default App;
