import * as constants from './constants'
import { fromJS } from 'immutable'
const defaultState = fromJS({
    current: 1,
    photovisible: false,
    statisticsvisible: false,
})
export default (state = defaultState, action) => {
    switch (action.type) {
        case constants.GET_MAINTAIN_LIST:
            return state.set('maintainlist', action.maintainlist)
        case constants.GET_MAINTAIN_TOTAL:
            return state.set('maintaintotal', action.maintaintotal)
        case constants.SET_INPUT_VALUE:
            return state.set('value', action.value)
        case constants.SET_CURRENT:
            return state.set('current', action.current)
        case constants.PHOTO_SHOW:
            return state.merge({
                photovisible: true,
                photourl: action.photourl
            })
        case constants.PHOTO_HIDE:
            return state.set('photovisible', false)
        case constants.STATISTICS_SHOW:
            return state.set('statisticsvisible', true)
        case constants.STATISTICS_HIDE:
            return state.set('statisticsvisible', false)
        case constants.GET_STATISTICS_COUNT:
            return state.set('statisticscount', action.statisticscount)
        case constants.GET_STATISTICS_LIST:
            return state.set('statisticslist', action.statisticslist)
        default:
            return state
    }

}