import * as constants from './constants'
import axios from 'axios';
import Http from '../../../http'
export const getmaintainlist = (result) => ({
    type: constants.GET_MAINTAIN_LIST,
    maintainlist: result
})
export const getmaintaintotal = (result) => ({
    type: constants.GET_MAINTAIN_TOTAL,
    maintaintotal: result
})
export const setinputvalue = (value) => ({
    type: constants.SET_INPUT_VALUE,
    value: value
})
export const setcurrent = (current) => ({
    type: constants.SET_CURRENT,
    current: current
})
export const photoshow = (item) => ({
    type: constants.PHOTO_SHOW,
    photourl: item
})
export const photohide = () => ({
    type: constants.PHOTO_HIDE
})
export const statisticsshow = () => ({
    type: constants.STATISTICS_SHOW
})
export const statisticshide = () => ({
    type: constants.STATISTICS_HIDE
})
export const getstatisticscount = (count) => ({
    type: constants.GET_STATISTICS_COUNT,
    statisticscount: count
})
export const getstatisticslist = (list) => ({
    type: constants.GET_STATISTICS_LIST,
    statisticslist: list
})
export const getmaintain = () => {
    return (dispatch) => {
        axios.get(Http.apiHost + '/API/Maintrecords/getList?page=1&size=10').then((res) => {
            const result = res.data.data
            dispatch(getmaintainlist(result.list))
            dispatch(getmaintaintotal(result.count))
        })
    }
}
export const paginationchange = (current, value) => {
    return (dispatch) => {
        if (value == undefined || value == '') {
            axios.get(Http.apiHost + '/API/Maintrecords/getList?page=' + current + '&size=10').then((res) => {
                const result = res.data.data
                dispatch(getmaintainlist(result.list))
                dispatch(getmaintaintotal(result.count))
                dispatch(setcurrent(current))
            })
        }
        else {
            axios.get(Http.apiHost + '/API/Maintrecords/getList?page=' + current + '&size=10&key=' + value).then((res) => {
                const result = res.data.data
                dispatch(getmaintainlist(result.list))
                dispatch(getmaintaintotal(result.count))
                dispatch(setcurrent(current))
            })
        }
    }
}
export const inputsearch = (value) => {
    return (dispatch) => {
        axios.get(Http.apiHost + '/API/Maintrecords/getList?page=1&size=10&key=' + value).then((res) => {
            const result = res.data.data
            dispatch(getmaintainlist(result.list))
            dispatch(getmaintaintotal(result.count))
            dispatch(setinputvalue(value))
            dispatch(setcurrent(1))
        })
    }
}
export const searchchange = (e) => {
    return (dispatch) => {
        if (e == '') {
            axios.get(Http.apiHost + '/API/Maintrecords/getList?page=1&size=10').then((res) => {
                const result = res.data.data
                dispatch(getmaintainlist(result.list))
                dispatch(getmaintaintotal(result.count))
                dispatch(setcurrent(1))
                dispatch(setinputvalue())
            })
        }
    }
}
export const statisticsremove = () => {
    return (dispatch) => {
        dispatch(getstatisticscount())
        dispatch(getstatisticslist())
        dispatch(statisticshide())
    }
}
export const getcontent = (year) => {
    return (dispatch) => {
        axios.get(Http.apiHost + '/API/Maintrecords/getCostCount?time=' + year).then((res) => {
            const result = res.data.data
            dispatch(getstatisticscount(result.count))
            dispatch(getstatisticslist(result.list))
        }).catch(() => {
            alert('没有当前选择年份的信息')
        })
    }
}
